import React, { useRef } from "react";
import {
    Box,
    MenuItem,
    TextField,
    Typography,
    Accordion,
    Grid,
} from "@mui/material";

import { WhiteBorder, vCenter } from "../../styled-Component/elements";
import TablesView from "./Tables";

import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import styled from "@emotion/styled";
import { Virtuoso } from "react-virtuoso";

const TableBodyStyle = {
    backgroundColor: "black",
    padding: "10px",
    fontSize: "13px",
    color: "white",
};

const header = [
    "Item Code",
    "Name",
    "status",
    "expiry",
    "Batch Number",
    "Po Qty",
    "GRN qty",
    "GRN FOC qty",
];

const StyledBox = styled(Box)`
    margin-bottom: 1rem; /* Adjust as needed */
    padding: 0;

    /* Target AccordionSummary content for both expanded and collapsed states */
    .MuiAccordionSummary-content {
        margin: 0;
    }

    /* Target AccordionSummary content when expanded */
    &.Mui-expanded .MuiAccordionSummary-content {
        margin: 0; /* Maintain margin removal */
    }

    /* Target AccordionSummary root for vertical alignment */
    .MuiAccordionSummary-root {
        align-items: flex-end; /* Align content to bottom */
    }
`;

const StyledTextField = styled(TextField)`
    border: 1px solid white;

    & .MuiInputBase-input {
        padding: 0;
        text-align: center;
        font-size: 15px;
        cursor: pointer;
        color: white !important;
        /* border: 1px solid white; */
    }

    & .MuiInputBase-root {
        padding-top: 4px;
        color: white !important;
    }
`;

export default function BasicTable({ loadedData }) {
    const [GrnStatus, setGrnStatus] = React.useState("0");

    const virtus = useRef(null);

    const status = [
        {
            value: "0",
            label: "All",
        },
        {
            value: "1",
            label: "valid",
        },
        {
            value: "2",
            label: "inValid",
        },
    ];

    const filteredArray = () => {
        let data = loadedData.filter((item) => {
            return GrnStatus == "0"
                ? item
                : GrnStatus == "1"
                ? item.status == "Valid"
                : GrnStatus == "2"
                ? item.status == "Invalid"
                : null;
        });

        return data;
    };

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "end",
                    color: "white",
                    my: 2,
                    "& .MuiInputLabel-root": {
                        color: "white !important",
                    },
                }}
            >
                <TextField
                    id="outlined-select-currency"
                    select
                    onChange={(e) => setGrnStatus(e.target.value)}
                    InputProps={{
                        style: { borderColor: "white" }, // Replace "yourColor" with your desired color
                    }}
                    sx={{
                        borderColor: "white",
                        color: "white",
                        "& .MuiSelect-select": {
                            borderColor: "white",
                            p: 1,
                            outline: "1px solid white",
                        },
                        "& .MuiOutlinedInput-input": {
                            padding: "5px 14px !important",
                        },
                        "& .MuiOutlinedInput-root": {
                            color: "white",
                            pr: 0,
                            textAlign: "center",
                            p: 0.8,
                        },
                    }}
                    defaultValue={GrnStatus}
                >
                    {status.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
            </Box>

            {/*    <TableContainer component={Paper}>
      <Table sx={{border:"1px solid grey" }} aria-label="simple table">
        <TableHead sx={{...TableHeadIssue,border:"0px !important"}}>
          <TableRow sx={{backgroundColor:"transparent",border:"0px !important"}}>
          {header.map((item,index)=>{
            return  <TableCell key={index} sx={{border:0,backgroundColor:"grey",padding:"10px",fontSize:"13px",fontWeight:"bold",color:"black",borderBottom:"1px solid grey"}} align="center">{item}</TableCell>
          })}
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredArray().length > 0 && filteredArray().map((item,index) => {
            return  <TableRow
            key={index}
            >
        
            <TableCell sx={{...TableBodyStyle}} align="center">{item?.item_code}</TableCell>
            <TableCell sx={{...TableBodyStyle}} align="center">{item?.item_name}</TableCell>
            <TableCell sx={{...TableBodyStyle,fontWeight:"bold",color:item.status =="Valid" ? "#64FD02" : "red"}} align="center">{item.status}</TableCell>
              <TableCell sx={{...TableBodyStyle}} align="center">{item?.grn_exp_date}</TableCell>
              <TableCell sx={{...TableBodyStyle}} align="center">{item.grn_batch_no}</TableCell>
              <TableCell sx={{...TableBodyStyle}} align="center">{item.po_qty}</TableCell>
              <TableCell sx={{...TableBodyStyle}} align="center">{item.grn_qty}</TableCell>
              <TableCell sx={{...TableBodyStyle}} align="center">{item.grn_foc_qty}</TableCell>
            </TableRow>
          })}

          


            

            

        </TableBody>
      </Table>
        </TableContainer>*/}

            {/*New Table*/}
            {filteredArray().length > 0 && (
                <Virtuoso
                    totalCount={filteredArray().length}
                    ref={virtus}
                    style={{ height: "90vh" }}
                    data={filteredArray()}
                    itemContent={(_, item) => (
                        <StyledBox
                            sx={{
                                mb: 1.5,
                                p: 0,
                                "& .css-ivyevq-MuiButtonBase-root-MuiAccordionSummary-root":
                                    { alignItems: "end" },
                                "&.css-eqpfi5-MuiAccordionSummary-content.Mui-expanded":
                                    {
                                        m: 0,
                                    },
                                "& .css-eqpfi5-MuiAccordionSummary-content": {
                                    margin: 0,
                                },
                            }}
                        >
                            <Accordion
                                sx={{
                                    backgroundColor: "transparent",
                                    m: 0,
                                    p: 0,
                                }}
                            >
                                <AccordionSummary
                                    sx={{
                                        height: "auto",
                                        width: "100%",
                                        p: 0,
                                        m: 0,
                                    }}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                >
                                    <Box
                                        disabled={_ == 2 ? true : false}
                                        sx={{
                                            p: 1,
                                            width: "100%",
                                            opacity:
                                                "item.status" <= 0 ? 0.5 : 1,
                                            cursor: "pointer",
                                            backgroundColor: "black",
                                            ...WhiteBorder,
                                            borderColor:
                                                item?.status != "Valid"
                                                    ? "red"
                                                    : "green",
                                            borderWidth: 1,
                                            mt: 0,
                                        }}
                                    >
                                        <Grid
                                            container
                                            sx={{ ...vCenter, p: 0, m: 0 }}
                                        >
                                            <Grid
                                                item
                                                xs={2.5}
                                                sx={{ ...vCenter }}
                                            >
                                                <Typography
                                                    sx={{
                                                        textAlign: "start",
                                                        color: "white",
                                                        fontSize: "14px",
                                                    }}
                                                >
                                                    {item.item_code}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={7}>
                                                <Typography
                                                    sx={{
                                                        textAlign: "center",
                                                        px: 1,
                                                        color: "white",
                                                        fontSize: "14px",
                                                        wordBreak: "break-word",
                                                        fontWeight: 500,
                                                    }}
                                                >
                                                    {item.item_name}
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={2.5}
                                                sx={{
                                                    ...vCenter,
                                                    px: 0.5,
                                                    justifyContent: "end",
                                                }}
                                            >
                                                <StyledTextField
                                                    id="outlined-required"
                                                    value={
                                                        item?.req_qty
                                                            ? Number(
                                                                  item?.req_qty + item?.req_foc_qty /
                                                                      item?.unit_fraction
                                                              ).toLocaleString()
                                                            : 0
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails sx={{ width: "100%", p: 0 }}>
                                    <Box
                                        sx={{
                                            borderTop: "1px solid white",
                                            p: 0,
                                        }}
                                    >
                                        <TablesView data={item} />
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                        </StyledBox>
                    )}
                />
            )}

            {/*
                filteredArray().length > 0 &&
                filteredArray().map((item,index) => {
                    return (
                        <StyledBox
                            key={index}
                            sx={{
                                mb: 1.5,
                                p: 0,
                                "& .css-ivyevq-MuiButtonBase-root-MuiAccordionSummary-root":
                                    { alignItems: "end" },
                                "&.css-eqpfi5-MuiAccordionSummary-content.Mui-expanded":
                                    {
                                        m: 0,
                                    },
                                "& .css-eqpfi5-MuiAccordionSummary-content": {
                                    margin: 0,
                                },
                            }}
                        >
                            <Accordion
                                sx={{
                                    backgroundColor: "transparent",
                                    m: 0,
                                    p: 0,
                                }}
                            >
                                <AccordionSummary
                                    sx={{
                                        height: "auto",
                                        width: "100%",
                                        p: 0,
                                        m: 0,
                                    }}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                >
                                    <Box
                                        disabled={index == 2 ? true : false}
                                        key={index}
                                        sx={{
                                            p: 1,
                                            width: "100%",
                                            opacity:
                                                "item.status" <= 0 ? 0.5 : 1,
                                            cursor: "pointer",
                                            backgroundColor: "black",
                                            ...WhiteBorder,
                                            borderColor:
                                                item?.status != "Valid"
                                                    ? "red"
                                                    : "green",
                                            borderWidth: 1,
                                            mt: 0,
                                        }}
                                    >
                                        <Grid
                                            container
                                            sx={{ ...vCenter, p: 0, m: 0 }}
                                        >
                                            <Grid xs={2.5} sx={{ ...vCenter }}>
                                                <Typography
                                                    sx={{
                                                        textAlign: "start",
                                                        color: "white",
                                                        fontSize: "14px",
                                                    }}
                                                >
                                                    {item.item_code}
                                                </Typography>
                                            </Grid>
                                            <Grid xs={7}>
                                                <Typography
                                                    sx={{
                                                        textAlign: "center",
                                                        px: 1,
                                                        color: "white",
                                                        fontSize: "14px",
                                                        wordBreak: "break-word",
                                                        fontWeight: 500,
                                                    }}
                                                >
                                                    {item.item_name}
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                xs={2.5}
                                                sx={{
                                                    ...vCenter,
                                                    px: 0.5,
                                                    justifyContent: "end",
                                                }}
                                            >
                                                <StyledTextField
                                                    id="outlined-required"
                                                    value={
                                                        item?.po_qty
                                                            ? Number(
                                                                  item?.po_qty /
                                                                      item?.unit_fraction
                                                              ).toLocaleString()
                                                            : 0
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails sx={{ width: "100%", p: 0 }}>
                                    <Box
                                        sx={{
                                            borderTop: "1px solid white",
                                            p: 0,
                                        }}
                                    >
                                        <TablesView data={item} />
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                        </StyledBox>
                    );
                })
            */}

            {filteredArray().length <= 0 && (
                <Typography
                    sx={{
                        color: "white",
                        textAlign: "center",
                        borderBottom: "1px solid lightgrey",
                        pb: 2,
                        my: 4,
                    }}
                >
                    Data Not Exist
                </Typography>
            )}
        </>
    );
}
