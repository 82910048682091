import {
  Box,
  Typography,
  Button,
  Grid,
  Paper,
  Divider,
  Input,
  TextField,
  MenuItem,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import QrCodeIcon from "@mui/icons-material/QrCodeScanner";
import { useNavigate } from "react-router-dom";
import BoxIcon from "../../Assets/box.png";
import TablesView from "./helper/Tables";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import Select from "@mui/material/Select";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { AddInvoice, AddItem } from "../../store/counterSlice";
import styled from "@emotion/styled";
import SearchIcon from "@mui/icons-material/Search";
import axios from "../../Axios/Axios";
import { No_Data } from "../../helper/Nodata";
import { Url } from "../../Axios/Axios";
//Colors

import {
  textWhite,
  TextBlue,
  darkBlue,
  green,
  red,
} from "../../styled-Component/colors";

//STYLES
import {
  hCenter,
  vCenter,
  colorWhite,
  WhiteBorder,
  bgDarkBlue,
  pointer,
} from "../../styled-Component/elements";

//Icons
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { LinearLoader } from "../../helper/linearLaoder";
//images

import PopulModel from "./helper/Tables";
import { Loader } from "../../helper/loader";
import { QrCodeScanner } from "@mui/icons-material";

const StyledTextField = styled(TextField)`
  border: 1px solid white;

  & .MuiInputBase-input {
    padding: 0;
    text-align: center;
    font-size: 14px;
    cursor: pointer;
    color: white !important;
    /* border: 1px solid white; */
  }

  & .MuiInputBase-root {
    padding-top: 4px;
    color: white !important;
  }
`;

const StyledBox = styled(Box)`
  margin-bottom: 1rem; /* Adjust as needed */
  padding: 0;

  /* Target AccordionSummary content for both expanded and collapsed states */
  .MuiAccordionSummary-content {
    margin: 0;
  }

  /* Target AccordionSummary content when expanded */
  &.Mui-expanded .MuiAccordionSummary-content {
    margin: 0; /* Maintain margin removal */
  }

  /* Target AccordionSummary root for vertical alignment */
  .MuiAccordionSummary-root {
    align-items: flex-end; /* Align content to bottom */
  }
`;

function TablePage() {
  const storeItems = useSelector((state) => state.counter.items);

  const location = useLocation();
  const [itemsList, setItemList] = useState([]);
  const dispatch = useDispatch();
  const [openFilter, setOpenFilter] = useState(false);
  const [sortFilter, setSortFilter] = useState(false);

  const [filters, setFilter] = React.useState("All");
  const [sortby, setSortby] = React.useState("Recent_update");
  const [filterIcon, setfilterIcon] = useState(true);
  const [loading, setloading] = useState(false);

  const [searchData, setSearchData] = useState("");

  const filterItem = (value) => {
    setSearchData(value);
  };

  const handleChange = (event) => {
    setFilter(event.target.value);
    setOpenFilter(false);
  };

  const handleSortChange = (event) => {
    setSortby(event.target.value);
    setSortFilter(false);
  };

  const userJSON = localStorage.getItem("user");
  const user = userJSON && JSON.parse(userJSON);

  // useEffect(() => {
  //     setloading(true);
  //     const headers = {
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${user.token}`,
  //     };

  //     if (location?.state?.where === "invoiceList") {
  //         axios
  //             .post(
  //                 `${Url}invoice-items`,
  //                 {
  //                     invoice_no: location?.state?.data?.invoice_no,
  //                     wCode: location?.state?.data?.wCode,
  //                 },
  //                 headers
  //             )
  //             .then((done) => {
  //                 dispatch(AddInvoice(done.data.data));

  //                 let filteredData;
  //                 filteredData = done.data.data;

  //                 if (filteredData.length > 0) {
  //                     setItemList(filteredData);
  //                 }
  //                 setloading(false);
  //             })
  //             .catch((err) => setloading(false));
  //     }

  //     if (location?.state?.where === "productSearch") {
  //         axios
  //             .post(
  //                 `${Url}single-item`,
  //                 {
  //                     item: location?.state?.data,
  //                 },
  //                 headers
  //             )
  //             .then((done) => {
  //                 let filteredData;
  //                 filteredData = [done.data.data];

  //                 if (filteredData.length > 0) {
  //                     setItemList(filteredData);
  //                 }
  //                 setloading(false);
  //             })
  //             .catch((err) => setloading(false));
  //     }
  // }, []);

  useEffect(() => {
    const fetchData = async () => {
      setloading(true);
      const token = localStorage.getItem("userToken");
      if (!token) {
        throw new Error("User token is not available");
      }
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      try {
        if (location?.state?.where === "invoiceList") {
          const response = await fetch(`${Url}invoice-items`, {
            method: "POST",
            headers,
            body: JSON.stringify({
              invoice_no: location?.state?.data?.invoice_no,
              wCode: location?.state?.data?.wCode,
            }),
          });

          const done = await response.json();
          dispatch(AddInvoice(done.data));

          let filteredData = done.data;

          console.log(done.data);

          if (filteredData.length > 0) {
            setItemList(filteredData);
          }
        }

        if (location?.state?.where === "productSearch") {
          const response = await fetch(`${Url}single-item`, {
            method: "POST",
            headers,
            body: JSON.stringify({
              item: location?.state?.data,
            }),
          });

          const done = await response.json();
          let filteredData = [done.data];

          if (filteredData.length > 0) {
            setItemList(filteredData);
          }
        }
      } catch (err) {
        console.log(err);
      } finally {
        setloading(false);
      }
    };

    fetchData();
  }, []);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleOpen = () => {
    setOpenFilter(!openFilter);
  };

  const handleSortOpen = () => {
    setSortFilter(!sortFilter);
  };

  const filterDate = () => {
    let filteredDate = itemsList.filter((data) =>
      filters == "All"
        ? data
        : filters == "0"
        ? data?.item_qty == 0
        : data?.item_qty > 0
    );

    if (sortby == "Recent_update") {
      return filteredDate
        .reverse()
        .filter((itm) =>
          searchData != ""
            ? itm.item_name.toLowerCase().includes(searchData.toLowerCase())
            : itm && itm
        );
    } else {
      return filteredDate
        .reverse()
        .filter((itm) =>
          searchData != ""
            ? itm.item_name.toLowerCase().includes(searchData.toLowerCase())
            : itm && itm
        );
    }
  };

  return (
    <>
      {/*Model*/}

      {/*Model End*/}
      <Box sx={{ px: 2 }}>
        <Box>
          <Grid container sx={{ vCenter, pt: 2 }}>
            <Grid xs={1.5} sx={{ ...vCenter }}>
              <ArrowBackIosNewIcon
                onClick={() => {
                  navigate(-1);
                }}
                sx={{
                  fontSize: "20px",
                  padding: 0.5,
                  border: `1px solid ${TextBlue}`,
                  color: TextBlue,
                  borderRadius: "6px",
                }}
              />
            </Grid>
            <Grid xs={10.5}>
              {/* <Typography sx={{color:"white",fontSize:"14px",fontWeight:500}}>Tap on the desired item's barcode icon once you've picked up the product.</Typography> */}
            </Grid>
          </Grid>
        </Box>

        {/*-----------------Sort By & Filter--------------------*/}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: 2,
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                {
                  padding: "6px 0 1px 0 !important",
                  textAlign: "center",
                  color: "white",
                },
            }}
          >
            <Typography
              sx={{
                color: "white",
                fontSize: "12px",
                wordBreak: "keep-all",
                cursor: "pointer",
              }}
              onClick={handleSortOpen}
            >
              Sort By
            </Typography>

            <FormControl
              sx={{ width: "80%", mx: "end", color: "#FFFFFF" }}
              size="small"
            >
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                open={sortFilter}
                value={sortby}
                sx={{
                  visibility: sortFilter ? "visible" : "hidden",
                  color: "white !important",
                  border: "1px solid white",
                  p: 0,
                  fontSize: "12px",
                }}
                onChange={handleSortChange}
              >
                <MenuItem value={"Recent_update"} selected={true}>
                  Recent update
                </MenuItem>
                <MenuItem value={"Long_back_update"}>Long back update</MenuItem>
              </Select>
            </FormControl>
          </Box>

          {/*Filter*/}

          <Box
            sx={{
              width: "55%",
              display: "flex",
              alignItems: "center",
              "& .css-11u5sdsdsds3oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                {
                  padding: "6px 0 1px 0 !important",
                  textAlign: "center",
                },
            }}
          >
            <FormControl sx={{ width: "100%", mx: "end" }} size="small">
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                open={openFilter}
                value={filters}
                sx={{
                  visibility: openFilter ? "visible" : "hidden",
                  color: "white !important",
                  border: "1px solid white",
                  p: 0,
                  fontSize: "12px",
                }}
                onChange={handleChange}
              >
                <MenuItem value={"All"} selected={true}>
                  All
                </MenuItem>
                <MenuItem value={"0"}>pending</MenuItem>
                <MenuItem value={"1"}>completed</MenuItem>
              </Select>
            </FormControl>
            <IconButton>
              <FilterAltIcon
                sx={{
                  color: "white",
                  fontSize: "22px",
                  visibility: filterIcon ? "visible" : "hidden",
                }}
                onClick={handleOpen}
              />
            </IconButton>
          </Box>
        </Box>

        {/* -----------------------Search Box-------------------- */}
        <FormControl sx={{ width: "80%", ml: "10%" }} variant="outlined">
          <OutlinedInput
            sx={{
              backgroundColor: "white",
              fontSize: "12px",
              color: "Navy",
            }}
            id="outlined-adornment-password"
            size="small"
            placeholder={"Leroco 8mg..."}
            value={searchData}
            name="invoice"
            onChange={(e) => {
              filterItem(e.target.value);
            }}
            // endAdornment={
            //     <InputAdornment position="end">
            //         <IconButton
            //             aria-label="toggle password visibility"
            //             onClick={
            //                 ()=>{console.log("hello")}
            //             }
            //             onMouseDown={
            //                 ()=>{console.log("hello")}
            //             }
            //             edge="end"
            //         >
            //             <SearchIcon
            //                 size="small"
            //                 sx={{ fontSize: "15px" }}
            //             />
            //         </IconButton>
            //     </InputAdornment>
            // }
          />
        </FormControl>

        {/*InVoice Lists*/}
        {/*New List*/}
        <Box sx={{ mt: 3 }}>
          {!loading &&
            filterDate().length > 0 &&
            filterDate().map((item, index) => {
              return (
                <StyledBox
                  sx={{
                    mb: 1.5,
                    p: 0,
                    "& .css-ivyevq-MuiButtonBase-root-MuiAccordionSummary-root":
                      { alignItems: "end" },
                    "&.css-eqpfi5-MuiAccordionSummary-content.Mui-expanded": {
                      m: 0,
                    },
                    "& .css-eqpfi5-MuiAccordionSummary-content": {
                      margin: 0,
                    },
                  }}
                >
                  <Accordion
                    sx={{
                      backgroundColor: "transparent",
                      m: 0,
                      p: 0,
                    }}
                  >
                    <AccordionSummary
                      sx={{
                        height: "auto",
                        width: "100%",
                        p: 0,
                        m: 0,
                      }}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      {/* borderColor:item.item_qty <= 0  ? "red": 'white'  */}
                      <Box
                        disabled={index == 2 ? true : false}
                        key={index}
                        sx={{
                          p: 1,
                          width: "100%",

                          cursor:
                            item.item_qty <= 0 ? "not-allowed" : "allowed",
                          backgroundColor: "black",
                          ...WhiteBorder,
                          borderColor:
                            item?.status != "Valid" ? "red" : "green",
                          borderWidth: 1,
                          mt: 0,
                        }}
                      >
                        <Grid
                          container
                          sx={{
                            ...vCenter,
                            p: 0,
                            m: 0,
                          }}
                        >
                          <Grid xs={2} sx={{ ...vCenter }}>
                            <Box
                              component={"img"}
                              src={BoxIcon}
                              sx={{
                                width: "20px",
                                ...vCenter,
                              }}
                            ></Box>
                          </Grid>
                          <Grid xs={8}>
                            <Typography
                              sx={{
                                color: "rgba(255, 255, 255, 1)",
                                fontSize: "12px",
                                wordBreak: "break-word",
                                fontWeight: 500,
                              }}
                            >
                              {item.item_name} {item.item_code}{" "}
                            </Typography>
                          </Grid>
                          <Grid
                            xs={2}
                            sx={{
                              ...vCenter,
                              justifyContent: "end",
                              gap: 1.5,
                            }}
                          >
                            {/* <QrCodeIcon onClick={()=>navigate('/QrCode',{state:{data:item}})} sx={{...colorWhite,...pointer}} /> */}
                            {/*<TextField
    onClick={()=>navigate('/QrCode',{state:{data:item,where:location.state.where}})}
    // inputProps={{ readOnly: true }}
    sx={{color:"white !important",border:"1px solid white",'& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input':{padding:0,textAlign:"center",color:"white !important",paddingTop:"4px",cursor:"pointer",fontSize:"14px"}}}
    id="outlined-required"
value={item?.item_qty}/>*/}

                            <Typography
                              sx={{ color: "white", fontSize: "16px" }}
                            >
                              Qty:{" "}
                              {!isNaN(item?.item_qty) &&
                              !isNaN(item?.unit_fraction)
                                ? item?.item_qty / item?.unit_fraction
                                : "-"}{" "}
                              / {item.totQty ? item.totQty : "-"}
                            </Typography>
                            {/* <StyledTextField
                              
                            //   id="outlined-required"
                              //   value={
                              //     !isNaN(item?.item_qty) &&
                              //     !isNaN(item?.unit_fraction)
                              //       ? "Qty: " +
                              //         item?.item_qty / item?.unit_fraction
                              //       : "Qty: -"
                              //   }
                              //   value={<QrCodeScanner />}
                            /> */}
                            <QrCodeScanner
                              sx={{
                                ...colorWhite,
                                ...pointer,
                              }}
                              onClick={() =>
                                navigate("/QrCode", {
                                  state: {
                                    data: item,
                                    newData: {
                                      invoice_no:
                                        location?.state?.data?.invoice_no,
                                      wCode: location?.state?.data?.wCode,
                                    },
                                    where: location.state.where,
                                  },
                                })
                              }
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails sx={{ width: "100%", p: 0 }}>
                      <Box
                        sx={{
                          borderTop: "1px solid white",
                          p: 0,
                        }}
                      >
                        <TablesView data={item} />
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                </StyledBox>
              );
            })}
        </Box>
        {!loading && filterDate().length <= 0 && <No_Data />}
        {loading && <Loader />}
        {/*Button*/}
        {!loading && itemsList.length > 0 && (
          <Box sx={{ mt: 5, mb: 3 }}>
            <Box sx={{ textAlign: "center" }}>
              <Button
                onClick={() => {
                  navigate("/InvoiceList");
                }}
                variant="outlined"
                sx={{
                  mx: "auto",
                  width: "70%",
                  p: 1,
                  fontSize: "12px",
                  ...WhiteBorder,
                  color: "white",
                  backgroundColor: "black",
                }}
              >
                Save & Complete
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
}

export default TablePage;
