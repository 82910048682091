import axios from "axios";
import {token} from '../helper/token'
// export const Url = "http://localhost:8000/api/";
export const Url = "https://wareve.adetive.com/api/"




const Axios = axios.create({
    baseURL: Url,
    headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Content-Type, Authorization, Accept",
        "Access-Control-Allow-Credentials": "true",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${token && token}`,
    },
    timeout: 50000,
});

export default Axios;
