import {
  Box,
  Typography,
  Button,
  Grid,
  Paper,
  Tooltip,
  Avatar,
  Menu,
  MenuItem,
  ListItemIcon,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { textWhite, TextBlue } from "../../styled-Component/colors";

import {
  hCenter,
  vCenter,
  colorWhite,
  pointer,
} from "../../styled-Component/elements";

import { useNavigate } from "react-router-dom";

import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import FilledInput from "@mui/material/FilledInput";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { isNumberOnly } from "../../helper/NumberOnlyCheck";
import LogoutIcon from "@mui/icons-material/Logout";

//Icons
import SearchIcon from "@mui/icons-material/Search";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import QrCode2Icon from "@mui/icons-material/QrCode2";

//images

import Invoice from "../../Assets/Group_33594.png";
import Product from "../../Assets/Group.png";

import { Toast } from "../../helper/toaster";

//Model
import PopulModel from "./helper/popupMdel";
import axios from "../../Axios/Axios";
import { useSelector, useDispatch } from "react-redux";
import { AddInvoice, AddItem } from "../../store/counterSlice";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useQuery } from "@tanstack/react-query";
import { Logout, PersonAdd, Settings } from "@mui/icons-material";
import BarCodeInputValidator from "../../helper/barCodeValidator";
import HeaderDrawer from "../../helper/HeaderDrawer";

function Dashboard() {
  const [invoiceList, setInvoiceList] = useState([]);
  const [itemList, setItemList] = useState([]);

  const [showPassword, setShowPassword] = React.useState(false);
  const userJSON = localStorage.getItem("user");
  const user = userJSON && JSON.parse(userJSON);
  const type = localStorage.getItem("type");
  const [inputdata, setInputdata] = useState({
    invoice: "",
    product: "",
  });

  const imputhandler = (e) => {
    const { name, value } = e.target;
    setInputdata((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const dispatch = useDispatch();

  // useEffect(() => {
  //     axios
  //         .get("/items")
  //         .then((done) => {
  //             dispatch(AddItem(done.data.data));

  //             setItemList(done.data.data);
  //         })
  //         .catch((err) => console.log(err, "err"));

  //     axios
  //         .get("/invoices")
  //         .then((done) => {
  //             dispatch(AddInvoice(done.data.data));
  //             setInvoiceList(done.data.data);
  //         })
  //         .catch((err) => console.log(err, "err"));
  // }, []);

  const navigate = useNavigate();

  const stylesForIcon = {
    fontSize: "35px",
    color: "white",
    border: "1px solid white",
    borderRadius: 50,
    padding: ".5rem",
  };

  const RecentActivityList = [
    {
      Logo: <PermIdentityIcon sx={stylesForIcon} />,
      Content: "You have distributed",
      description: "12 Packages",
    },
    {
      Logo: <MailOutlineIcon sx={stylesForIcon} />,
      Content: "New Dispatch Requested",
      description: "12 Packages",
    },
  ];

  const [open, setOpen] = useState(false);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleLogout = () => {
    setAnchorEl(null);
    localStorage.removeItem("user");
    localStorage.removeItem("userToken");
    navigate("/sign-in");
    Toast("success", "Logout Successful", 1600);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuOpen = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box sx={{ width: "93%", mx: "auto", mt: 2 }}>
        {/* <Box
          sx={{
            ...hCenter,
            ...vCenter,
            boxSizing: "border-box",
            px: 2,
            backgroundColor: "#001B3B",
            py: "1.4rem",
            borderRadius: 3,
          }}
        >
          <ArrowBackIosNewIcon
            onClick={() => {
              navigate("/mainPage");
            }}
            sx={{
              fontSize: "20px",
              padding: 0.5,
              border: `1px solid ${TextBlue}`,
              color: TextBlue,
              borderRadius: "6px",
            }}
          />
          <Box>
            <Typography
              variant="h6"
              display="block"
              sx={{
                ...colorWhite,
                fontWeight: 600,
                my: 0,
                py: 0,
                lineHeight: "1rem ",
              }}
            >
              Hello {user["name"]}
            </Typography>
            <Typography
              variant="span"
              display="block"
              sx={{
                ...colorWhite,
                my: 0,
                py: 1,
                fontSize: "12px",
              }}
            >
              Level 1
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              gap: "0.5rem",
              alignItems: "center",
            }}
          >
            <NotificationsNoneIcon sx={{ color: textWhite }} />

            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <Tooltip title="Account settings">
                  <IconButton
                    onClick={handleClick}
                    size="small"
                    aria-controls={menuOpen ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={menuOpen ? "true" : undefined}
                  >
                    <Avatar sx={{ width: 22, height: 22 }}>
                      {user["name"][0].toUpperCase()}
                    </Avatar>
                  </IconButton>
                </Tooltip>
              </Box>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={menuOpen}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 22,
                      height: 22,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&::before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{
                  horizontal: "right",
                  vertical: "top",
                }}
                anchorOrigin={{
                  horizontal: "right",
                  vertical: "bottom",
                }}
              >
                <MenuItem onClick={handleLogout}>
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  Logout
                </MenuItem>
              </Menu>
            </>
          </Box>
        </Box> */}
        <Box
          sx={{
            ...hCenter,
            ...vCenter,
            boxSizing: "border-box",
            px: 2,
            backgroundColor: "#001B3B",
            py: "1.4rem",
            borderRadius: 3,
          }}
        >
          <HeaderDrawer />
          <Box>
            <Typography
              variant="h6"
              display="block"
              sx={{
                ...colorWhite,
                fontWeight: 600,
                my: 0,
                py: 0,
                lineHeight: "1rem ",
              }}
            >
              Hello {user["name"]}
            </Typography>
            <Typography
              variant="span"
              display="block"
              sx={{
                ...colorWhite,
                my: 0,
                py: 1,
                fontSize: "12px",
              }}
            >
              Level 1
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              gap: "0.5rem",
              alignItems: "center",
            }}
          >
            <NotificationsNoneIcon sx={{ color: textWhite }} />

            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <Tooltip title="Account settings">
                  <IconButton
                    onClick={handleClick}
                    size="small"
                    aria-controls={menuOpen ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={menuOpen ? "true" : undefined}
                  >
                    <Avatar sx={{ width: 22, height: 22 }}>
                      {user["name"][0].toUpperCase()}
                    </Avatar>
                  </IconButton>
                </Tooltip>
              </Box>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={menuOpen}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 22,
                      height: 22,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&::before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{
                  horizontal: "right",
                  vertical: "top",
                }}
                anchorOrigin={{
                  horizontal: "right",
                  vertical: "bottom",
                }}
              >
                <MenuItem onClick={handleLogout}>
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  Logout
                </MenuItem>
              </Menu>
            </>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              color: TextBlue,
              my: 3,
              fontSize: "1rem",
              fontWeight: 500,
            }}
          >
            How do you like to search?
          </Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          {/*seatch boxes*/}
          <Box sx={{ width: "48%" }}>
            <Box
              sx={{
                padding: "8px",
                backgroundColor: "black",
                border: "1px solid white",
                borderRadius: "5px",
              }}
            >
              <FormControl sx={{ width: "100%" }} variant="outlined">
                <OutlinedInput
                  sx={{
                    backgroundColor: "white",
                    fontSize: "12px",
                    color: "Navy",
                  }}
                  id="outlined-adornment-password"
                  size="small"
                  placeholder={
                    type === "Placer" ? "PO Number" : "Invoice Number"
                  }
                  value={inputdata.invoice}
                  name="invoice"
                  onChange={(e) => {
                    // if(isNumberOnly(e)){
                    imputhandler(e);
                    // }
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        <SearchIcon size="small" sx={{ fontSize: "15px" }} />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <Typography
                component={"p"}
                sx={{
                  ...pointer,
                  my: 0.5,
                  fontSize: "11px",
                  color: "grey",
                }}
                onClick={() =>
                  navigate("/InvoiceList", {
                    state: {
                      data: "",
                      where: "seeMore",
                      invoiceList,
                      itemList,
                    },
                  })
                }
              >
                See More
              </Typography>

              {/*Bottom Box and arrow*/}
              <Box sx={{ ...vCenter, ...hCenter, mt: 3 }}>
                {/*left image*/}
                <Box sx={{ position: "relative" }}>
                  <Box
                    sx={{
                      position: "absolute",
                      left: -12,
                      bottom: -30,
                      width: "35px",
                    }}
                    component={"img"}
                    src={Invoice}
                  ></Box>
                </Box>
                {/*left image ENd*/}

                <Box>
                  <ArrowForwardIcon
                    onClick={() => {
                      if (inputdata.invoice == "" && inputdata.product == "") {
                        Toast("error", "Empty data can't be process", 1500);
                      } else {
                        navigate("/InvoiceList", {
                          state: {
                            data: inputdata.invoice,
                            where: "search",

                            invoiceList,
                            itemList,
                          },
                        });
                      }
                    }}
                    sx={{ ...colorWhite, pointer }}
                    size="small"
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          {/*Right*/}

          {/*search boxes*/}
          <Box sx={{ width: "48%" }}>
            <Box
              sx={{
                padding: "8px",
                backgroundColor: "black",
                border: "1px solid white",
                borderRadius: "5px",
              }}
            >
              <FormControl sx={{ width: "100%" }} variant="outlined">
                <OutlinedInput
                  sx={{
                    backgroundColor: "white",
                    fontSize: "12px",
                    color: "Navy",
                  }}
                  id="outlined-adornment-password"
                  size="small"
                  placeholder="Item Code / Bar Code"
                  value={inputdata.product}
                  name="product"
                  onChange={(e) => {
                    imputhandler(e);
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        <SearchIcon size="small" sx={{ fontSize: "15px" }} />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>

              {/*Bottom Box and arrow*/}
              <Box sx={{ ...hCenter, mt: 4.7 }}>
                {/*left image*/}

                <ArrowForwardIcon
                  onClick={() => {
                    if (inputdata.invoice == "" && inputdata.product == "") {
                      Toast("error", "Empty data can't be process", 1500);
                    } else {
                      BarCodeInputValidator(inputdata.product);
                      toggleDrawer();
                    }
                  }}
                  sx={{ ...colorWhite, pointer, mb: 1 }}
                  size="small"
                />
              </Box>

              <Box sx={{ position: "relative" }}>
                <Box
                  sx={{
                    position: "absolute",
                    right: -12,
                    bottom: -20,
                    width: "50px",
                  }}
                  component={"img"}
                  src={Product}
                ></Box>
              </Box>
              {/*left image ENd*/}
              <Box></Box>
            </Box>
          </Box>
        </Box>
        <Typography
          component={"p"}
          sx={{ mt: 4, mb: 1, fontSize: "13px", color: "white" }}
        >
          Recent Activities
        </Typography>

        {RecentActivityList.map((item, index) => {
          return (
            <Box
              key={index}
              sx={{
                padding: ".8rem",
                backgroundColor: "black",
                border: "1px solid white",
                borderRadius: "10px",
                mb: 2,
              }}
            >
              <Grid container>
                <Grid
                  item
                  xs={2.5}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Box>{item.Logo}</Box>
                </Grid>
                <Grid
                  item
                  xs={9.5}
                  sx={{ padding: 1, boxSizing: "border-box" }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {/*TEXT*/}
                    <Box>
                      <Typography
                        sx={{
                          ...colorWhite,
                          fontWeight: "500",
                          lineHeight: "1.3rem",
                          fontSize: "13.5px",
                        }}
                      >
                        {item.Content}
                      </Typography>
                      <Typography
                        sx={{
                          ...colorWhite,
                          fontWeight: "500",
                          lineHeight: "1.1rem",
                          fontSize: "11px",
                        }}
                      >
                        {item.description}
                      </Typography>
                    </Box>

                    {/*Arrow Icon*/}

                    <Box
                      sx={{
                        backgroundColor: "lightgrey",
                        display: "flex",
                        justifyContent: "center",
                        padding: "3.5px",
                        borderRadius: "15px",
                      }}
                    >
                      <ArrowForwardIosIcon
                        sx={{
                          color: "grey",
                          fontSize: "12px",
                        }}
                        onClick={toggleDrawer}
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          );
        })}
      </Box>

      <PopulModel
        open={open}
        setOpen={setOpen}
        selected={inputdata.product}
        invoiceList={invoiceList}
        itemList={itemList}
      />
    </>
  );
}

export default Dashboard;
