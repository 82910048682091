import {
  Box,
  Typography,
  Button,
  Grid,
  Paper,
  List,
  ListSubheader,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  Stack,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { textWhite, TextBlue } from "../../styled-Component/colors";

import {
  hCenter,
  vCenter,
  colorWhite,
  pointer,
} from "../../styled-Component/elements";

import { useNavigate } from "react-router-dom";

//Icons
import { ExpandMore, ExpandLess } from "@mui/icons-material";

import { path_access, higherAuth, userrole } from "../../helper/token";
import { ApiCall } from "../../helper/Apicall";
import { MainMenu, paths } from "../../helper/constant";

//Model
import PopulModel from "./helper/popupMdel";

// pages
function Dashboard() {
  const [showPassword, setShowPassword] = React.useState(false);
  const [inputdata, setInputdata] = useState({
    invoice: "",
    product: "",
  });

  const role = userrole;
  const navigate = useNavigate();

  const stylesForIcon = {
    fontSize: "35px",
    color: "white",
    border: "1px solid white",
    borderRadius: 50,
    padding: ".5rem",
  };

  const pathsAcess = path_access();
  const [activePath, setActivePath] = useState({ MainMenu: [], SubMenu: [] });
  const [whichMenu, setWhichMenu] = useState("main");
  const [selectedMenu, setSelectedMenu] = useState(0);

  const changeMenu = () => {
    setWhichMenu((prev) => (prev === "main" ? "sub" : "main"));
  };

  const datas_from_db = async () => {
    let { data, error } = await ApiCall("menus", "get");
    if (data) {
      let MainMenu = data.filter((itm) => itm.menuid < 1000);
      let SubMenu = data.filter((itm) => itm.menuid > 1000);

      setActivePath({ MainMenu, SubMenu });
    } else {
      console.log(1002);
    }
  };

  useEffect(() => {
    datas_from_db();
  }, []);

  const [open, setOpen] = useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const SubMenuSelection = (id) => {
    setWhichMenu("sub");
    setSelectedMenu(id);
  };

  const [menuOpen, setMenuOpen] = React.useState(null);

  const handleMenuToggle = (id) => {
    setMenuOpen((prevOpenMenu) => (prevOpenMenu === id ? null : id));
  };

  return (
    <>
      {/* <Box>
        <Box sx={{ my: 2, display: "flex", justifyContent: "flex-end" }}>
          {whichMenu === "sub" && (
            <Button
              onClick={changeMenu}
              variant="contained"
              size="small"
              sx={{ textTransform: "capitalize", mr: 4 }}
            >
              Back
            </Button>
          )}
        </Box>

        <Stack spacing={2} sx={{ padding: 2 }}>
          {MainMenu?.length > 0 &&
            MainMenu.map((item, index) => (
              <List
                key={index}
                sx={{
                  width: "100%",
                  backgroundColor: "black",
                  border: "1px solid white",
                  color: "white",
                }}
                component="nav"
                aria-labelledby="nested-list-subheader"
              >
                <ListItemButton onClick={() => handleMenuToggle(item.id)}>
                  <ListItemIcon>
                    <item.Logo sx={stylesForIcon} />
                  </ListItemIcon>
                  <ListItemText primary={item.Content} />
                  {menuOpen === item.id ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse
                  in={menuOpen === item.id}
                  timeout="auto"
                  unmountOnExit
                >
                  {paths
                    .filter((item1) => item1.refId == item.id)
                    .map((item2, index2) => {
                      let { Logo } = item2;

                      return pathsAcess === "all" ? (
                        activePath?.SubMenu?.filter(
                          (item3) =>
                            item3.menuid == Number(item2.id) &&
                            item3.isactive === 1
                        ) && (
                          <ListItemButton
                            sx={{ pl: 4 }}
                            key={index2}
                            onClick={() => {
                              if (item2.Content == "Placer") {
                                localStorage.setItem("type", "Placer");
                              } else if (item2.Content == "Collector") {
                                localStorage.setItem("type", "Collector");
                              } else if (item2.Content == "BTV Picking") {
                                localStorage.setItem("type", "BTV Picking");
                              } else if (
                                item2.Content == "Purchase Receiving"
                              ) {
                                localStorage.setItem(
                                  "type",
                                  "Purchase Receiving"
                                );
                              }
                              navigate(item2.to);
                            }}
                          >
                            <ListItemIcon>
                              <Logo sx={{ color: "white" }} />
                            </ListItemIcon>
                            <ListItemText primary={item2.Content} />
                          </ListItemButton>
                        )
                      ) : (
                        <ListItemButton
                          sx={{ pl: 4 }}
                          key={index2}
                          onClick={() => {
                            if (item2.Content == "Placer") {
                              localStorage.setItem("type", "Placer");
                            } else if (item2.Content == "Collector") {
                              localStorage.setItem("type", "Collector");
                            } else if (item2.Content == "BTV Picking") {
                              localStorage.setItem("type", "BTV Picking");
                            } else if (item2.Content == "Purchase Receiving") {
                              localStorage.setItem(
                                "type",
                                "Purchase Receiving"
                              );
                            }

                            navigate(item2.to);
                          }}
                        >
                          <ListItemIcon>
                            <Logo sx={{ color: "white" }} />
                          </ListItemIcon>
                          <ListItemText primary={item2.Content} />
                        </ListItemButton>
                      );
                    })}
                </Collapse>
              </List>
            ))}
        </Stack>

        <PopulModel
          open={open}
          setOpen={setOpen}
          selected={inputdata.product}
        />
      </Box> */}
    </>
  );
}

export default Dashboard;
