import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Url } from "../../Axios/Axios";
import { useDispatch } from "react-redux";
import { Toast } from "../../helper/toaster";

export default function SignIn() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    setLoginData({ ...loginData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (loginData.email.trim() && loginData.password.trim()) {
      try {
        const response = await axios.post(`${Url}login`, {
          userid: loginData.email,
          password: loginData.password,
        });

        localStorage.setItem(
          "user",
          JSON.stringify({ ...response.data.data, userid: loginData.email })
        );

        localStorage.setItem("userToken", response.data.data.token);
        Toast("success", "Login Successfull", 2000);
        navigate("/mainPage");
      } catch (error) {
        Toast("error", "Something went wrong", 2000);
        console.log(error);
      }
    } else {
      Toast("error", "email/employee id &  password required", 2000);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "rgb(255, 255, 255)",
          borderRadius: "1rem",
          mx: "auto",
        }}
      >
        <Typography component="h1" variant="h6" sx={{ mt: 2 }}>
          Sign in
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={{
            p: 1,
            mt: 1,
          }}
        >
          <TextField
            size="small"
            required
            fullWidth
            id="email"
            label="Email / employee id"
            name="email"
            autoComplete="email"
            onChange={handleChange}
            autoFocus
          />
          <TextField
            size="small"
            required
            fullWidth
            sx={{ mt: 3 }}
            name="password"
            label="Password"
            type="password"
            onChange={handleChange}
            id="password"
            autoComplete="current-password"
          />

          <Box sx={{ textAlign: "center" }}>
            <Button
              type="submit"
              variant="contained"
              sx={{
                mt: 9,
                mb: 2,
                fontSize: "14px",
                padding: "5px 10px 3px 10px",
              }}
            >
              Sign In
            </Button>
          </Box>
        </Box>
      </Box>
    </Container>
  );
}
