import React from 'react'
import { Typography } from '@mui/material' 


export function No_Data() {
  return (
    <div>
     <Typography component={"h2"} sx={{textAlign:"center",padding:1,fontWeight:"bold",fontSize:"16px",color:"white"}}>Data Not Exist</Typography>
    </div>
  )
}

 

