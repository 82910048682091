import {
  Box,
  Typography,
  Button,
  Grid,
  Paper,
  LinearProgress,
  MenuItem,
  TextField,
  Tooltip,
  Avatar,
  Menu,
  ListItemIcon,
} from "@mui/material";
import React, { lazy, useEffect, useState } from "react";
import { token } from "../../helper/token";
import {
  hCenter,
  vCenter,
  colorWhite,
  pointer,
} from "../../styled-Component/elements";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { textWhite, TextBlue } from "../../styled-Component/colors";
import { useNavigate, useLocation } from "react-router-dom";
import TablesView from "./Table";
import "bootstrap/dist/css/bootstrap.css";
import Form from "react-bootstrap/Form";
import Buttons from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tabled from "react-bootstrap/Table";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import axios from "../../Axios/Axios";
import { Toast } from "../../helper/toaster";
import { BgBlackImage } from "../../styled-Component/colors";
import { Url } from "../../Axios/Axios";
import { WhiteBorder } from "../../styled-Component/elements";
import { Virtuoso } from "react-virtuoso";
import { Logout } from "@mui/icons-material";
import BarCodeInputValidator from "../../helper/barCodeValidator";
import HeaderDrawer from "../../helper/HeaderDrawer";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { green } from "@mui/material/colors";
const PrModelPopUp = lazy(() => import("../QrCode/helper/popupMdelForPR"));

const Po_list = lazy(() => import("./Po_list"));
function Receiving() {
  const [showResults, setShowResults] = React.useState(false);
  const [intialTime, setintialTime] = React.useState(false);
  const [Loading, setLoading] = React.useState(false);
  const [viewPOlist, setviewPOlist] = React.useState(false);
  const [loadedData, setLoadedData] = React.useState([]);
  const [supplier, setSupplier] = useState("supplier name");
  const [levelData, setLevelData] = useState({
    L1: "",
    L2: "",
    L1_date: "",
    L2_date: "",
  });
  const [bendingPoList, setBendingpoist] = useState([]);

  const [selectedPo, SetSelectedPo] = useState();
  const userJSON = localStorage.getItem("user");
  const user = userJSON && JSON.parse(userJSON);

  const today = new Date();
  const formattedDate = today.toString("MMMM dd, yyyy");
  const [value, setValue] = React.useState(formatDate(formattedDate));
  function formatDate(date) {
    return `${dayjs(date).format("YYYY-MM-DD")}`;
  }

  const [GrnStatus, setGrnStatus] = React.useState(0);
  const [fromDate, setFromDate] = React.useState("");
  const [loadData, setLoad] = useState({
    wh_cd: "",
    Po_no: "",
    bar_code: "",
  });
  const grnStatusCOlor = ["DodgerBlue", "red", "green"];

  const navigate = useNavigate();
  const Location = useLocation();
  const status = [
    {
      value: 0,
      label: "Not Opened ",
    },
    {
      value: 1,
      label: "InCompleted",
    },
    {
      value: 2,
      label: "Completed ",
    },
  ];

  const TableStyle = {
    backgroundColor: BgBlackImage,
    color: "white !important",
  };

  const TableTextColor = { color: "white", fontSize: "17px" };

  const TotalCount = (() => {
    let foc = 0;
    let qty = 0;
    let valid = 0;
    let invalid = 0;

    if (loadedData.length > 0) {
      loadedData.forEach((data) => {
        foc += data.grn_foc_qty
          ? Number(data.grn_foc_qty) / Number(data.unit_fraction)
          : 0;
        qty += data.grn_qty
          ? Number(data.grn_qty) / Number(data.unit_fraction)
          : 0;
        if (data.status === "Valid") {
          valid += 1;
        } else {
          invalid += 1;
        }
      });
    } else {
      foc = 0;
      qty = 0;
      valid = 0;
      invalid = 0;
    }

    return {
      foc,
      qty,
      valid,
      invalid,
    };
  })();

  const StatusChangeCall = async (value) => {
    const Statusdata = {
      po_no: loadData.Po_no,
      wh_cd: loadData.wh_cd,
      po_value: 24,
      is_completed: 1,
      valid: TotalCount.valid,
      in_valid: TotalCount.invalid,
    };

    // const headers = {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${user.token}`,
    // };

    const token = localStorage.getItem("userToken");
    if (!token) {
      throw new Error("User token is not available");
    }
    try {
      const response = await fetch(`${Url}update-grn-total`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(Statusdata),
      });
      // if (!response.ok) {
      //     throw new Error(
      //         `An error has occurred: ${response.statusText}`
      //     );
      // }
      const done = await response.json();
      Toast("success", "GRN Status Changed Successfully", 1600);
    } catch (error) {
      console.log(error);
    }

    // axios
    //     .post(`${Url}update-grn-total`, Statusdata, headers)
    //     .then((done) => {
    //         Toast("success", "GRN Status Changed Successfully", 1600);
    //     })
    //     .catch((err) => {
    //         console.log(err.response.data);
    //     });
  };

  const loadDatahandler = (e) => {
    if (e.target.name === "bar_code") {
      const [warehouse, number] = e.target.value.split("-");
      const wh = warehouse?.trim();
      const num = number?.trim()?.split("-")?.pop();
      const numStr = num?.slice(0, -1);

      setLoad({ wh_cd: wh, Po_no: numStr });
    } else {
      setLoad((prev) => ({
        ...prev,
        [e.target.name]: e.target.value.trim(),
      }));
    }
  };

  const axiosCall = async (data) => {
    setShowResults(true);
    setintialTime(true);
    setLoading(true);

    try {
      const tokens = localStorage.getItem("userToken");
      if (!token) {
        throw new Error("User token is not available");
      }

      const response = await fetch(`${Url}purchase-order`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${tokens}`,
        },
        body: JSON.stringify(data),
      });

      const done = await response.json();
      if (done.data.length > 0) {
        setSupplier(done.data[done.data.length - 1].supplier_name);
        setLevelData({
          L1: done.data[done.data.length - 1].L1,
          L2: done.data[done.data.length - 1].L2,
          L1_date: done.data[done.data.length - 1].L1_date,
          L2_date: done.data[done.data.length - 1].L2_date,
        });

        setGrnStatus(done.data[done.data.length - 1].is_completed);
        done.data.pop();

        setLoadedData([...done.data]);
        setLoading(false);
      } else {
        setLoadedData([]);
        setLoading(false);
        Toast("error", "Invalid PO Number or Warehouse Number", 1600);
      }
    } catch (error) {
      console.log(error.response);
      setLoadedData([]);
      setLoading(false);
      Toast("error", "Invalid PO Number or Warehouse Number", 1600);
    }

    // const headers = {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${user.token}`,
    // };

    // axios
    //     .post(`${Url}purchase-order`, data, headers)
    //     .then((done) => {
    //         if (done.data.data.length > 0) {
    //             setSupplier(
    //                 done.data.data[done.data.data.length - 1].supplier_name
    //             );
    //             setGrnStatus(
    //                 done.data.data[done.data.data.length - 1]
    //                     .is_completed == 1
    //                     ? 2
    //                     : 1
    //             );
    //             done.data.data.pop();

    //             setLoadedData([...done.data.data]);
    //             setLoading(false);
    //             console.log(done.data.data);
    //         } else {
    //             setLoadedData([]);
    //             setLoading(false);
    //             // Invalid PO Number or Warehouse Number
    //             // Toast("error", "Invalid PO Number or Warehouse Number", 1600);
    //         }
    //     })
    //     .catch((err) => {
    //         console.log(err);
    //         setLoading(false);
    //         setLoadedData([]);
    //         Toast("error", "Invalid PO Number or Warehouse Number", 1600);
    //     });
  };

  const loadCall = () => {
    setviewPOlist(false);
    let data = {
      wh_cd: loadData?.wh_cd?.trim(),
      Po_no: loadData?.Po_no?.trim(),
    };

    if (
      data.wh_cd == "" ||
      data.Po_no == "" ||
      data.Po_no == " " ||
      data.wh_cd == " "
    ) {
      Toast("error", "WH_Cd & PO no must not be empty", 1600);
    } else {
      axiosCall(data);
    }
  };

  useEffect(() => {
    if (Location.state?.where == "qrpage") {
      setLoad(Location.state?.data);
      axiosCall(Location.state?.data);
    }
  }, []);

  const handleClearLoad = () => {
    setLoad({ wh_cd: "", Po_no: "", bar_code: "" });
    setLoadedData([]);
    setintialTime(false);
    setBendingpoist([]);
    setShowResults(false);
    setBendingpoist([]);
  };

  const [open, setOpen] = useState(false);
  const [popupData, setPopupData] = useState([]);
  const BarCodeValidator = () => {
    const validBarNumber = "06285147014934";

    // console.log(validQrNumber)

    axios
      .post(`${Url}check-barcode`, {
        barcode: validBarNumber,
      })
      .then((done) => {
        const newItem = loadedData?.filter(
          (item) => item.item_code === done.data.data.item_code
        );

        if (newItem.length > 0) {
          setOpen(true);
          setPopupData(newItem);
        } else {
          setOpen(false);
          Toast("error", "This Barcode is not matched", 1600);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleLogout = async () => {
    setAnchorEl(null);
    localStorage.removeItem("user");
    localStorage.removeItem("userToken");
    navigate("/sign-in");
    Toast("success", "Logout Successful", 1600);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuOpen = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [barCodeInputValue, setBarCodeInputValue] = useState("");

  const handleBarInputChange = (e) => {
    // setBarCodeInputValue(e.target.value);
    if (e.key === "Enter") {
      handlebarCodeChecker(e.target.value);
    }
  };

  const handleBarCodeInputClear = () => {
    document.getElementById("barcode-input").value = "";
  };

  const handlebarCodeChecker = async (validBarNumber) => {
    try {
      const token = localStorage.getItem("userToken");
      if (!token) {
        throw new Error("User token is not available");
      }
      const newValidBarNumber = BarCodeInputValidator(validBarNumber);
      const response = await fetch(`${Url}check-barcode`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ barcode: newValidBarNumber }),
      }).then((response) => response.json());

      const newItem = loadedData?.filter(
        (item) => item.item_code === response.data.item_code
      );

      if (newItem.length > 0) {
        setPopupData(newItem);
        setOpen(true);
      } else {
        setOpen(false);
        Toast("error", "This Barcode is not matched", 1600);
      }
    } catch (error) {
      console.error("Error during barcode validation:", error);
      Toast("error", "This Barcode is not matched", 1600);
    }
  };

  const l_1approved = async (level) => {
    axios
      .post(
        `${Url}update-level`,
        {
          Po_no: loadData.Po_no || selectedPo.Po_no,
          wh_cd: loadData.wh_cd || selectedPo.wh_cd,
          Level: level,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token()}`,
          },
        },
        { fromData: true }
      )
      .then((done) => {
        Toast("success", `${level} Approved`, 1600);

        setLevelData((prevState) => {
          return {
            ...prevState,
            [level]: done.data.data[level],
          };
        });
      })
      .catch((err) => {
        Toast("error", err?.response?.data?.message, 1600);
      });
  };

  const backCallFromTable = () => {
    setviewPOlist(true);
    SetSelectedPo();
    // setLoadedData([])
  };

  const Results = () => (
    <div id="results" className="search-results">
      <br />
      <TableContainer
        component={Paper}
        sx={{
          position: "static",
          width: { xs: "100%", md: "75%" },
          mx: "auto",
        }}
      >
        <Table sx={{ minWidth: "100%" }} aria-label="simple table">
          {/*<TableHead>
                      <TableRow>
                        <TableCell>Dessert (100g serving)</TableCell>
                        <TableCell align="right">Calories</TableCell>
                      </TableRow>
                    </TableHead>*/}
          <TableBody sx={{ border: "2px solid white" }}>
            <TableRow
              sx={{
                ...TableStyle,
                "&:last-child td, &:last-child th": {
                  border: 0,
                },
              }}
            >
              <TableCell sx={{ ...TableTextColor }} component="th" scope="row">
                Po Date
              </TableCell>
              <TableCell sx={{ ...TableTextColor }} align="right">
                {loadedData[0]?.Po_date ? loadedData[0]?.Po_date : "-"}
              </TableCell>
            </TableRow>

            <TableRow
              sx={{
                ...TableStyle,
                "&:last-child td, &:last-child th": {
                  border: 0,
                },
              }}
            >
              <TableCell sx={{ ...TableTextColor }} component="th" scope="row">
                Supplier Name
              </TableCell>
              <TableCell sx={{ ...TableTextColor }} align="right">
                {supplier}
              </TableCell>
            </TableRow>

            <TableRow
              sx={{
                ...TableStyle,
                "&:last-child td, &:last-child th": {
                  border: 0,
                },
              }}
            >
              <TableCell sx={{ ...TableTextColor }} component="th" scope="row">
                Po Number
              </TableCell>
              <TableCell sx={{ ...TableTextColor }} align="right">
                {loadedData[0]?.Po_no ? loadedData[0]?.Po_no : "-"}
              </TableCell>
            </TableRow>
            <TableRow
              sx={{
                ...TableStyle,
                "&:last-child td, &:last-child th": {
                  border: 0,
                },
              }}
            >
              <TableCell
                sx={{
                  ...TableTextColor,
                  pr: 0,
                  color: "#fff !important",
                }}
                component="th"
                scope="row"
              >
                <TextField
                  sx={{
                    width: "100%",
                    border: 1,
                    borderRadius: "2px",

                    "& .MuiInputBase-input": {
                      color: "white",
                    },
                  }}
                  id="barcode-input"
                  size="small"
                  onKeyPress={handleBarInputChange}
                />
              </TableCell>
              <TableCell sx={{ ...TableTextColor }} align="right">
                <Button
                  variant="danger"
                  size="small"
                  sx={{
                    "& .MuiButton-root": {
                      padding: "5px !important",
                    },
                    backgroundColor: "#dc3545",
                  }}
                  onClick={handleBarCodeInputClear}
                  // onClick={() =>
                  //     navigate("/scanner", {
                  //         state: {
                  //             data: loadData,
                  //             loadedData: loadedData,
                  //             where: "pr",
                  //         },
                  //     })
                  // }
                >
                  Clear
                </Button>
              </TableCell>
            </TableRow>

            {/* <TableRow
                            sx={{
                                ...TableStyle,
                                "&:last-child td, &:last-child th": {
                                    border: 0,
                                },
                            }}
                        >
                            <TableCell
                                sx={{ ...TableTextColor }}
                                component="th"
                                scope="row"
                            >
                                Scanned barcode
                            </TableCell>
                            <TableCell align="right">
                                <Button
                                    variant="contained"
                                    size="small"
                                    sx={{
                                        "& .MuiButton-root": {
                                            padding: "5px !important",
                                        },
                                    }}
                                    onClick={() =>
                                        navigate("/scanner", {
                                            state: {
                                                data: loadData,
                                                loadedData: loadedData,
                                                where: "pr",
                                            },
                                        })
                                    }
                                    // onClick={() =>
                                    //     navigate("/QrCode", {
                                    //         state: {
                                    //             data: loadData,
                                    //             loadedData: loadedData,
                                    //             where: "pr",
                                    //         },
                                    //     })
                                    // }

                                    // onClick={BarCodeValidator}
                                >
                                    Go
                                </Button>
                            </TableCell>
                        </TableRow> */}
          </TableBody>
        </Table>
      </TableContainer>

      {/*-----------------------------------Item Table View----------------------------*/}

      <TablesView loadedData={loadedData} setviewPOlist={backCallFromTable} />

      {/*-----------------------------------Status of GRN----------------------------*/}
      {/* <Box sx={{display:"flex",justifyContent:"center",color:"white",my:2,"& .MuiInputLabel-root":{
                color:"white !important",
              }}}>
              <TextField
              id="outlined-select-currency"
              select
              onChange={e =>{
                setGrnStatus(e.target.value)
                StatusChangeCall(e.target.value)
                }}
              InputProps={{
                style: { borderColor: "white" } 
              }}
              sx={{mt:1,
                borderColor:"white",
                color:"white",
                "& .MuiSelect-select":{
                borderColor:"white",
                p:1,
                outline : '1px solid white',
              },
              "& .MuiOutlinedInput-input":{
                padding:"5px 15px !important",
              },
              "& .MuiOutlinedInput-root":{
                color:grnStatusCOlor[GrnStatus],
                pr:0,
                textAlign: 'center',
                p:.8
              }
            }}
              label="Status"
              defaultValue={GrnStatus}
            >
              {status.map((option) => (
                <MenuItem key={option.value} sx={{color:grnStatusCOlor[option.value]}} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            </Box> */}
      <Box sx={{ textAlign: "center" }}>
        <Button
          variant="contained"
          sx={{
            mx: "auto",
            width: "70%",
            mt: 3,
            p: 1,
            fontSize: "12px",
            color: "white",
            "&:disabled": {
              backgroundColor: "rgb(25, 118, 210, 0.5)",
            },
          }}
          onClick={StatusChangeCall}
          disabled={levelData?.L1 === 1 && levelData?.L2 === 1 ? true : false}
        >
          Save & Complete
        </Button>
      </Box>
      <br />

      {/*---------------------Final TAble-----------------------*/}

      <TableContainer
        component={Paper}
        sx={{
          position: "static",
          width: { xs: "100%" },
          maxWidth: "500px",
          mx: "auto",
        }}
      >
        <Table sx={{ minWidth: "100%" }} aria-label="simple table">
          {/*<TableHead>
        <TableRow>
          <TableCell>Dessert (100g serving)</TableCell>
          <TableCell align="right">Calories</TableCell>
        </TableRow>
  </TableHead>*/}
          <TableBody sx={{ border: "2px solid white" }}>
            <TableRow
              sx={{
                ...TableStyle,
                "&:last-child td, &:last-child th": {
                  border: 0,
                },
              }}
            >
              <TableCell sx={{ ...TableTextColor }} component="th" scope="row">
                Total Quantity
              </TableCell>

              <TableCell
                sx={{ ...TableTextColor }}
                align="right"
              >{`(Qty ${Math.trunc(TotalCount.qty)} + FOC ${Math.trunc(
                TotalCount.foc
              )}) = ${Math.trunc(
                Number(TotalCount.qty) + Number(TotalCount.foc)
              )}`}</TableCell>
            </TableRow>

            <TableRow
              sx={{
                ...TableStyle,
                "&:last-child td, &:last-child th": {
                  border: 0,
                },
              }}
            >
              <TableCell sx={{ ...TableTextColor }} component="th" scope="row">
                Total Valid
              </TableCell>
              <TableCell sx={{ ...TableTextColor }} align="right">
                {TotalCount.valid}
              </TableCell>
            </TableRow>

            <TableRow
              sx={{
                ...TableStyle,
                "&:last-child td, &:last-child th": {
                  border: 0,
                },
              }}
            >
              <TableCell sx={{ ...TableTextColor }} component="th" scope="row">
                Total InValid
              </TableCell>
              <TableCell sx={{ ...TableTextColor }} align="right">
                {TotalCount.invalid}
              </TableCell>
            </TableRow>

            <TableRow
              sx={{
                ...TableStyle,
                "&:last-child td, &:last-child th": {
                  border: 0,
                },
              }}
            >
              <TableCell sx={{ ...TableTextColor }} component="th" scope="row">
                Not Attain
              </TableCell>
              <TableCell sx={{ ...TableTextColor }} align="right">
                {0}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Box
        sx={{ textAlign: "center", display: "flex", justifyContent: "center" }}
      >
        <Box maxWidth="300px" width="100%">
          {levelData?.L1 === 0 && GrnStatus === 1 ? (
            <Button
              variant="outlined"
              sx={{
                mx: "auto",
                width: "70%",
                mt: 3,
                p: 1,
                fontSize: "12px",
                border: "1px solid #4BB543",
                color: "white",
                backgroundColor: "#4BB543",
              }}
              onClick={() => l_1approved("L1")}
            >
              L1 Approve
            </Button>
          ) : levelData?.L1 === 1 ? (
            <Typography
              sx={{
                mx: "auto",
                width: "70%",
                mt: 3,
                p: 1,
                fontSize: "12px",
                color: "success.main",
                backgroundColor: "success.dark",
              }}
            >
              L1 approved
            </Typography>
          ) : null}
          {/* {levelData?.L1 === 0 && (
            <Button
              variant="outlined"
              sx={{
                mx: "auto",
                width: "70%",
                mt: 3,
                p: 1,
                fontSize: "12px",
                border: "1px solid #4BB543",
                color: "white",
                backgroundColor: "#4BB543",
              }}
              onClick={() => l_1approved("L1")}
            >
              L1 Approve
            </Button>
          )} */}
        </Box>
        <Box maxWidth="300px" width="100%">
          {levelData?.L1 === 1 && levelData?.L2 === 0 && (
            <Button
              variant="outlined"
              sx={{
                mx: "auto",
                width: "70%",
                mt: 3,
                p: 1,
                fontSize: "12px",
                border: "1px solid #4BB543",
                color: "white",
                backgroundColor: "#4BB543",
              }}
              onClick={() => l_1approved("L2")}
            >
              L2 Approve
            </Button>
          )}
          {levelData?.L2 === 1 && levelData?.L1 === 1 && (
            <Typography
              sx={{
                mx: "auto",
                width: "70%",
                mt: 3,
                p: 1,
                fontSize: "12px",
                color: "success.main",
                backgroundColor: "success.dark",
              }}
            >
              L2 approved
            </Typography>
          )}
        </Box>
      </Box>
      <PrModelPopUp
        open={open}
        setOpen={setOpen}
        where={"pr"}
        data={popupData[0]}
        loadData={loadData}
        axiosCall={axiosCall}
        loadedData={loadedData}
        L1={levelData?.L1}
        L2={levelData?.L2}
        setLoadedData={setLoadedData}
        location={Location ? Location : ""}
      />
      <br />
    </div>
  );

  const bendingCall = async () => {
    setviewPOlist(true);
    handleClearLoad();
    setLoading(true);
    const response = await fetch(`${Url}bending-order`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token()}`,
      },
      body: JSON.stringify({ date: value }),
    })
      .then((response) => response.json())
      .then((done) => {
        setBendingpoist(done.data);
        setLoading(!true);
      })
      .catch((err) => {
        console.log(err, "polist");
        setBendingpoist([]);
        setLoading(false);
      });
  };

  const viewPoCakll = (data1) => {
    setviewPOlist(false);
    const uploadData = {
      wh_cd: data1.wh_cd?.trim(),
      Po_no: data1.Po_no?.trim(),
    };
    SetSelectedPo(data1);
    axiosCall(uploadData);
  };

  const removeLevelApproval = async (reqData) => {
    console.log(reqData);
    try {
      const tokens = localStorage.getItem("userToken");
      if (!token) {
        throw new Error("User token is not available");
      }

      const data = {
        Po_no: reqData.Po_no,
        wh_cd: reqData.wh_cd,
        Level: "L0",
      };

      const response = await fetch(`${Url}update-level`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${tokens}`,
        },
        body: JSON.stringify(data),
      });

      const done = await response.json();
      if (response.ok) {
        await bendingCall();
      }
    } catch (error) {
      Toast("error", "Error", 1600);
    }
  };

  let NoDataBox = () => (
    <Box>
      <Typography
        sx={{
          color: "white",
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        No Data Found
      </Typography>
    </Box>
  );

  return (
    <>
      <Box sx={{ width: "93%", mx: "auto", mt: 2 }}>
        <Box
          sx={{
            ...hCenter,
            ...vCenter,
            boxSizing: "border-box",
            px: 2,
            backgroundColor: "#001B3B",
            py: "1.4rem",
            borderRadius: 3,
          }}
        >
          <HeaderDrawer />
          <Box>
            <Typography
              variant="h6"
              display="block"
              sx={{
                ...colorWhite,
                fontWeight: 600,
                my: 0,
                py: 0,
                lineHeight: "1rem ",
              }}
            >
              Purchase Receiving
            </Typography>
            <Typography
              variant="span"
              display="block"
              sx={{
                ...colorWhite,
                my: 0,
                py: 0,
                fontSize: "12px",
              }}
            ></Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
            }}
          >
            <NotificationsNoneIcon sx={{ color: textWhite }} />
            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <Tooltip title="Account settings">
                  <IconButton
                    onClick={handleClick}
                    size="small"
                    aria-controls={menuOpen ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={menuOpen ? "true" : undefined}
                  >
                    <Avatar sx={{ width: 22, height: 22 }}>
                      {user["name"][0].toUpperCase()}
                    </Avatar>
                  </IconButton>
                </Tooltip>
              </Box>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={menuOpen}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 22,
                      height: 22,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&::before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{
                  horizontal: "right",
                  vertical: "top",
                }}
                anchorOrigin={{
                  horizontal: "right",
                  vertical: "bottom",
                }}
              >
                <MenuItem onClick={handleLogout}>
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  Logout
                </MenuItem>
              </Menu>
            </>
          </Box>
        </Box>
        <div>
          {/* <input type="submit" value="Load" onClick={onClick} /> */}
          <br />

          <Box
            sx={{
              textAlign: "center",
              width: { xs: "80%", md: "35%" },
              mx: "auto",
            }}
          >
            <Box sx={{ textAlign: "start" }}>
              <Typography
                variant="span"
                display="block"
                sx={{
                  color: "white",
                  my: 0,
                  py: 0,
                  fontSize: "12px",
                }}
              >
                Bar Code number
              </Typography>
              <TextField
                sx={{
                  position: "static",
                  backgroundColor: "white",
                  fontSize: "12px",
                  borderRadius: 1,
                  width: "100%",
                }}
                id="outlined-adornment-password"
                size="small"
                placeholder="Bar Code Number"
                name="bar_code"
                value={loadData.bar_code}
                onChange={(e) => loadDatahandler(e)}
              />
            </Box>
            <Box sx={{ textAlign: "start", mt: 1 }}>
              <Typography
                variant="span"
                display="block"
                sx={{
                  color: "white",
                  my: 0,
                  py: 0,
                  fontSize: "12px",
                }}
              >
                Warehouse number
              </Typography>
              <TextField
                sx={{
                  position: "static",
                  backgroundColor: "white",
                  fontSize: "12px",
                  borderRadius: 1,
                  width: "100%",
                }}
                id="outlined-adornment-password"
                size="small"
                placeholder="Warehouse Number"
                name="wh_cd"
                value={loadData.wh_cd}
                onChange={(e) => loadDatahandler(e)}
              />
            </Box>

            <Box sx={{ textAlign: "start", mt: 1 }}>
              <Typography
                variant="span"
                display="block"
                sx={{
                  ...colorWhite,
                  my: 0,
                  py: 0,
                  fontSize: "12px",
                }}
              >
                PO Number
              </Typography>
              <TextField
                sx={{
                  backgroundColor: "white",
                  fontSize: "12px",
                  borderRadius: 1,
                  width: "100%",
                  position: "static",
                }}
                id="outlined-adornment-password"
                size="small"
                placeholder="PO Number"
                name="Po_no"
                value={loadData.Po_no}
                onChange={(e) => loadDatahandler(e)}
              />
            </Box>

            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}
            >
              <Buttons
                variant="primary"
                type="submit"
                disabled={
                  loadData.Po_no != "" &&
                  loadData.Po_no != " " &&
                  loadData.wh_cd != "" &&
                  loadData.wh_cd != ""
                    ? false
                    : true
                }
                onClick={loadCall}
              >
                Load
              </Buttons>
              <Buttons
                variant="danger"
                type="submit"
                onClick={handleClearLoad}
                sx={{ mr: 1 }}
              >
                Clear
              </Buttons>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mt: 3,
                alignItems: "center",
                gap: 3,
              }}
            >
              <Box
                className="Inputfiels"
                sx={{
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "start",
                }}
              >
                <Box
                  sx={{
                    my: 2,
                    width: "100%",

                    "& .MuiInputBase-input": {
                      color: "white !important",
                      fontWeight: "bold",
                      padding: "8px 8px 8px 8px",
                      fontSize: "14px",
                      textAlign: "center",
                    },
                    "& .MuiFormControl-root": {
                      border: ".5px solid white !important",
                    },
                    "& button": {
                      padding: "4px !important",
                      fontSize: "12px !important",
                      color: "white !important",
                    },
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      value={dayjs(value)}
                      onChange={(newValue) => {
                        const formattedDate =
                          dayjs(newValue).format("YYYY-MM-DD");
                        setValue(formattedDate);
                      }}
                    />
                  </LocalizationProvider>
                </Box>
              </Box>
              <Box>
                <Buttons variant="primary" type="submit" onClick={bendingCall}>
                  Pending
                </Buttons>
              </Box>
            </Box>

            {/* <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              Bending items
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  mt: 2,
                  mr: 4,
                  gap: 18,
                  width: "100%",
                }}
              >
                <Buttons variant="primary" type="submit" onClick={bendingCall}>
                  Pending
                </Buttons>
              </Box>
              Load and clear Buttons
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  mt: 2,
                  gap: 18,
                  width: "100%",
                }}
              >
                <Buttons
                  variant="primary"
                  type="submit"
                  disabled={
                    loadData.Po_no != "" &&
                    loadData.Po_no != " " &&
                    loadData.wh_cd != "" &&
                    loadData.wh_cd != ""
                      ? false
                      : true
                  }
                  onClick={loadCall}
                >
                  Load
                </Buttons>
                <Buttons
                  variant="danger"
                  type="submit"
                  onClick={handleClearLoad}
                  sx={{ mr: 1 }}
                >
                  Clear
                </Buttons>
              </Box>
            </Box> */}
          </Box>

          <br />

          <br />

          {Loading && (
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          )}
          {viewPOlist && bendingPoList?.length > 0 && (
            <Po_list
              viewPoCakll={viewPoCakll}
              polist={bendingPoList}
              removeLevelApproval={removeLevelApproval}
            />
          )}
          {intialTime && !Loading && loadedData.length > 0 && !viewPOlist && (
            <Results />
          )}

          {!Loading && viewPOlist && bendingPoList?.length <= 0 && (
            <NoDataBox />
          )}

          {intialTime && !Loading && loadedData.length <= 0 && !viewPOlist && (
            <NoDataBox />
          )}
        </div>
      </Box>
    </>
  );
}

export default Receiving;
