import React from "react";
import PropTypes from "prop-types";
import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { grey } from "@mui/material/colors";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import TableViewIcon from "@mui/icons-material/TableView";
import BarChartIcon from "@mui/icons-material/BarChart";
import { useLocation, useNavigate } from "react-router-dom";
//Color
import {
    TextBlue,
    darkBlue,
    overDarkBlue,
} from "../../../styled-Component/colors";
import { vCenter, colorWhite, Btn } from "../../../styled-Component/elements";
import TextField from "@mui/material/TextField";
import { isNumberOnly } from "../../../helper/NumberOnlyCheck";
import axios from "../../../Axios/Axios";
import { Toast } from "../../../helper/toaster";
import { Url } from "../../../Axios/Axios";

const drawerBleeding = 56;

const Root = styled("div")(({ theme }) => ({
    backgroundColor: "black",
}));

const StyledBox = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.mode === "light" ? "white" : grey[800],
}));

const Puller = styled("div")(({ theme }) => ({
    width: 70,
    height: 6,
    backgroundColor: theme.palette.mode === "light" ? TextBlue : grey[900],
    borderRadius: 3,
    position: "absolute",
    top: 8,
    left: "calc(50% - 35px)",
}));

function SwipeableEdgeDrawer(props) {
    const { window, open, setOpen, data, where, binLocation } = props;
    const navigate = useNavigate();

    const locationInit = useLocation();
    const location = locationInit.state.data;

    const userJSON = localStorage.getItem("user");
    const user = userJSON && JSON.parse(userJSON);

    const toggleDrawer = () => {
        setOpen(false);
    };

    // console.log(data);
    const [qty, setQty] = React.useState(
        Number(data.item_qty / data.unit_fraction)
    );
    const [allow, setAllow] = React.useState(true);
    const [errorMessage, setErrorMessage] = React.useState("");
    const type = localStorage.getItem("type");

    console.log(locationInit.state.newData);

    const updateCall = async () => {
        // const headers = {
        //     Accept: "application/json",
        //     "Content-Type": "application/json",
        //     Authorization: `Bearer ${user.token}`,
        // };
        // axios
        //     .post(`${Url}update-quantity`, { id: data.item_id, item_qty: qty }, headers)
        //     .then((res) => {
        //         toggleDrawer();
        //         Toast("success", "Quantity updated successfully", 1500);

        //         navigate(-1, { state: { data: data, where: where } });
        //     })
        //     .catch((err) => console.log(err.response.data));

        try {
            const token = localStorage.getItem("userToken");
            if (!token) {
                throw new Error("User token is not available");
            }

            if (qty !== "") {
                const response = await fetch(`${Url}update-quantity`, {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        itm_cd: data.item_code,
                        qty: qty * data.unit_fraction,
                        bin_loc: binLocation,
                        wh_cd: data.wh_cd,
                        po_no: data.po_no,
                    }),
                });

                toggleDrawer();
                Toast("success", "Quantity updated successfully", 1500);
                navigate(-1, { state: { data: data, where: where } });
            } else {
                setErrorMessage("Please Enter Quantity");
            }
        } catch (error) {
            console.log(error);
        }
        setAllow(true);
    };

    const handleChange = (e) => {
        const value = e.target.value === "" ? "" : Number(e.target.value);
        const maxQty = data.item_qty / data.unit_fraction;

        if (value > maxQty) {
            setErrorMessage(`Value cannot exceed ${maxQty}`);
        } else {
            setErrorMessage("");
            setQty(value);
        }
    };
    // console.log(qty);

    // This is used only for the example
    const container =
        window !== undefined ? () => window().document.body : undefined;

    return (
        <Root>
            <CssBaseline />
            {
                <Global
                    styles={{
                        ".MuiDrawer-root > .MuiPaper-root": {
                            height: `calc(70% - ${drawerBleeding}px)`,
                            overflow: "visible",
                            borderTopLeftRadius: "40px",
                            borderTopRightRadius: "40px",
                            borderTop: "2px solid white",
                            backgroundColor: "white",
                        },
                    }}
                />
            }

            <SwipeableDrawer
                container={container}
                anchor="bottom"
                open={open}
                onClose={() => toggleDrawer(false)}
                onOpen={() => {}}
                swipeAreaWidth={drawerBleeding}
                disableSwipeToOpen={false}
                ModalProps={{
                    keepMounted: true,
                }}
            >
                <StyledBox
                    sx={{
                        position: "absolute",
                        top: 0,
                        borderTopLeftRadius: 120,
                        borderTopRightRadius: 120,
                        visibility: "visible",
                        right: 0,
                        left: 0,
                    }}
                >
                    <Puller />
                    <Typography sx={{ mt: 5, mb: 1, textAlign: "center" }}>
                        Item Code: {data?.item_code}
                    </Typography>
                    <Typography sx={{ mb: 1, textAlign: "center" }}>
                        Bin Location: {binLocation}
                    </Typography>
                    <Typography sx={{ mb: 1, textAlign: "center" }}>
                        Quantity: {data?.grn_qty / data?.unit_fraction}
                    </Typography>
                    <Typography sx={{ mb: 1, textAlign: "center" }}>
                        FOC Quantity: {data?.grn_foc_qty / data?.unit_fraction}
                    </Typography>

                    <Box sx={{ mt: 4, textAlign: "center" }}>
                        {/* <TextField
                            id="outlined-basic"
                            value={qty}
                            defaultValue={0}
                            onChange={(e) => {
                                if (isNumberOnly(e)) {
                                    setQty(Number(e.target.value));
                                    if (
                                        localStorage.getItem("type") == "Placer"
                                    ) {
                                        if (e.target.value > data.item_qty) {
                                            setAllow(false);
                                        } else {
                                            setAllow(true);
                                        }
                                    }

                                    if (
                                        localStorage.getItem("type") ==
                                        "Collector"
                                    ) {
                                        if (e.target.value < data.item_qty) {
                                            setAllow(false);
                                        } else {
                                            setAllow(true);
                                        }
                                    }
                                }
                            }}
                            label="Enter Placing Quantity"
                            sx={{ fontSize: "14px" }}
                            variant="outlined"
                        /> */}

                        <TextField
                            id="outlined-basic"
                            value={qty}
                            type="number"
                            onChange={handleChange}
                            label={
                                type === "Placer"
                                    ? "Enter Placing Quantity"
                                    : "Enter Collecting Quantity"
                            }
                            sx={{ fontSize: "14px" }}
                            variant="outlined"
                            error={!!errorMessage}
                            helperText={errorMessage}
                        />
                        <Box
                            sx={{
                                display: "flex",
                                width: "90%",
                                justifyContent: "space-evenly",
                                mx: "auto",
                                mt: 4,
                            }}
                        >
                            <Btn
                                onClick={() => {
                                    toggleDrawer();
                                    navigate(-1);
                                    setAllow(true);
                                }}
                                variant="contained"
                                opacity={0.8}
                                hoverBgColor="red"
                                sx={{
                                    borderRadius: 2,
                                    backgroundColor: "red",
                                    px: "2rem",
                                }}
                            >
                                Cancel
                            </Btn>
                            <Btn
                                onClick={updateCall}
                                // disabled={allow}
                                variant="contained"
                                opacity={0.8}
                                hoverBgColor="green"
                                sx={{
                                    borderRadius: 2,
                                    backgroundColor: "green",
                                    px: "2rem",
                                }}
                            >
                                Submit
                            </Btn>
                        </Box>
                    </Box>
                </StyledBox>
            </SwipeableDrawer>
        </Root>
    );
}

SwipeableEdgeDrawer.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

export default SwipeableEdgeDrawer;
