import {
    Box,
    Typography,
    Button,
    Grid,
    Paper,
    LinearProgress,
    MenuItem,
    TextField,
    Tooltip,
    IconButton,
    Avatar,
    Menu,
    ListItemIcon,
} from "@mui/material";
import React, { lazy, useEffect, useState } from "react";
import {
    hCenter,
    vCenter,
    colorWhite,
    pointer,
} from "../../styled-Component/elements";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { textWhite, TextBlue } from "../../styled-Component/colors";
import { useNavigate, useLocation } from "react-router-dom";
import TablesView from "./Table";
import "bootstrap/dist/css/bootstrap.css";

import Buttons from "react-bootstrap/Button";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";

import TableRow from "@mui/material/TableRow";
import axios from "../../Axios/Axios";
import { Toast } from "../../helper/toaster";
import { BgBlackImage } from "../../styled-Component/colors";
import Axios, { Url } from "../../Axios/Axios";
import { WhiteBorder } from "../../styled-Component/elements";
import { Logout } from "@mui/icons-material";
import HeaderDrawer from "../../helper/HeaderDrawer";

const PrModelPopUp = lazy(() => import("../QrCode/helper/popupMdelForPR"));

function Picking() {
    const [showResults, setShowResults] = React.useState(false);
    const [intialTime, setintialTime] = React.useState(false);
    const [Loading, setLoading] = React.useState(false);
    const [loadedData, setLoadedData] = React.useState([]);
    const [supplier, setSupplier] = useState("supplier name");

    const userJSON = localStorage.getItem("user");
    const user = userJSON && JSON.parse(userJSON);

    const [GrnStatus, setGrnStatus] = React.useState(0);
    const [loadData, setLoad] = useState({
        wh_cd: "",
        itv_no: "",
        bar_code: "",
    });
    const grnStatusCOlor = ["DodgerBlue", "red", "green"];

    const navigate = useNavigate();
    const Location = useLocation();
    const status = [
        {
            value: 0,
            label: "Not Opened ",
        },
        {
            value: 1,
            label: "InCompleted",
        },
        {
            value: 2,
            label: "Completed ",
        },
    ];

    const TableStyle = {
        backgroundColor: BgBlackImage,
        color: "white !important",
    };

    const TableTextColor = { color: "white", fontSize: "17px" };

    console.log(loadedData);
    const TotalCount = (() => {
        let foc = 0;
        let qty = 0;
        let valid = 0;
        let invalid = 0;

        if (loadedData.length > 0) {
            loadedData.forEach((data) => {
                foc = foc + data.foc_qty / data.unit_fraction;
                qty = qty + data.qty / data.unit_fraction;
                if (data.status == "Valid") {
                    valid += 1;
                } else {
                    invalid += 1;
                }
            });
        } else {
            foc = 0;
            qty = 0;
            valid = 0;
            invalid = 0;
        }

        return {
            foc,
            qty,
            valid,
            invalid,
        };
    })();

    const StatusChangeCall = async (value) => {
        const Statusdata = {
            itv_no: loadData.itv_no,
            wh_cd: loadData.wh_cd,
            // po_value: 24,
            is_completed: 1,
            valid: TotalCount.valid,
            in_valid: TotalCount.invalid,
        };

        const headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
        };

        // axios
        //     .post(`${Url}update-btv-completion`, Statusdata, headers)
        //     .then((done) => {
        //         Toast("success", "BTV Status Changed Successfully", 1600);
        //     })
        //     .catch((err) => {
        //         console.log(err.response.data);
        //     });

        try {
            const response = await fetch(`${Url}update-btv-completion`, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${user.token}`,
                },
                body: JSON.stringify(Statusdata),
            });

            // if (!response.ok) {
            //     throw new Error(
            //         `An error has occurred: ${response.statusText}`
            //     );
            // }

            const done = await response.json();
            Toast("success", "BTV Status Changed Successfully", 1600);
        } catch (error) {
            console.log(error);
        }
    };

    // const loadDatahandler = (e) => {
    //     setLoad((prev) => ({
    //         ...prev,
    //         [e.target.name]: e.target.value.trim(),
    //     }));
    // };

    const loadDatahandler = (e) => {
        if (e.target.name === "bar_code") {
            const [warehouse, number] = e.target.value.split("-");
            const wh = warehouse.trim();
            const num = number.trim().split("-").pop();
            const numStr = num.slice(0, -1);
            console.log(numStr);
            setLoad({ wh_cd: wh, itv_no: numStr });
        } else {
            setLoad((prev) => ({
                ...prev,
                [e.target.name]: e.target.value.trim(),
            }));
        }
    };
    const axiosCall = async (data) => {
        setShowResults(true);
        setintialTime(true);
        setLoading(true);

        const token = localStorage.getItem("userToken");

        if (!token) {
            throw new Error("User token is not available");
        }

        try {
            const response = await fetch(`${Url}btv-list`, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(data),
            });

            // if (!response.ok) {
            //     throw new Error(
            //         `An error has occurred: ${response.statusText}`
            //     );
            // }

            const done = await response.json();

            console.log(data)
            if (done.data.length > 0) {
                setSupplier(done.data[done.data.length - 1].supplier_name);
                setGrnStatus(
                    done.data[done.data.length - 1].is_completed == 1 ? 2 : 1
                );
                done.data.pop();

                setLoadedData([...done.data]);
                setLoading(false);
            } else {
                setLoadedData([]);
                setLoading(false);
                Toast("error", "Invalid BTV Number or Warehouse Number", 1600);
            }
        } catch (error) {
            console.log(error);
            setLoading(false);
            setLoadedData([]);
        }

        // await axios.post(`${Url}btv-list`, data, headers)
        //     .then((done) => {
        //         if (done.data.data.length > 0) {
        //             setSupplier(
        //                 done.data.data[done.data.data.length - 1].supplier_name
        //             );
        //             setGrnStatus(
        //                 done.data.data[done.data.data.length - 1]
        //                     .is_completed == 1
        //                     ? 2
        //                     : 1
        //             );
        //             done.data.data.pop();

        //             setLoadedData([...done.data.data]);
        //             setLoading(false);
        //         } else {
        //             setLoadedData([]);
        //             setLoading(false);
        //             Toast(
        //                 "error",
        //                 "Invalid BTV Number or Warehouse Number",
        //                 1600
        //             );
        //         }
        //     })
        //     .catch((err) => {
        //         console.log(err);
        //         setLoading(false);
        //         setLoadedData([]);
        //     });
    };

    const loadCall = () => {
        let data = {
            wh_cd: loadData.wh_cd.trim(),
            itv_no: loadData.itv_no.trim(),
        };

        if (
            data.wh_cd == "" ||
            data.itv_no == "" ||
            data.itv_no == " " ||
            data.wh_cd == " "
        ) {
            Toast("error", "WH_Cd & PO no must not be empty", 1600);
        } else {
            axiosCall(data);
        }
    };

    useEffect(() => {
        if (Location.state?.where == "qrpage") {
            setLoad(Location.state?.data);
            axiosCall(Location.state?.data);
        }
    }, []);

    const [open, setOpen] = useState(false);
    const [popupData, setPopupData] = useState([]);
    const BarCodeValidator = () => {
        const validBarNumber = "06285147014934";

        // console.log(validQrNumber)

        axios
            .post(`${Url}check-barcode`, {
                barcode: validBarNumber,
            })
            .then((done) => {
                console.log(done.data);

                if (done.data.data.item_code === "") {
                    Toast("error", "This Barcode is invalid", 1600);
                    setOpen(false);
                } else {
                    const newItem = loadedData?.filter(
                        (item) => item.item_code === done.data.data.item_code
                    );
                    setOpen(true);
                    setPopupData(newItem);
                    console.log(newItem);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handlebarCodeChecker = async (validBarNumber) => {
        try {
            const token = localStorage.getItem("userToken");
            if (!token) {
                throw new Error("User token is not available");
            }

            const response = await fetch(`${Url}check-barcode`, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({ barcode: validBarNumber }),
            }).then((response) => response.json());

            const newItem = loadedData?.filter(
                (item) => item.item_code === response.data.item_code
            );

            if (newItem.length > 0) {
                setPopupData(newItem);
                setOpen(true);
            } else {
                setOpen(false);
                Toast("error", "This Barcode is not matched", 1600);
            }
        } catch (error) {
            console.error("Error during barcode validation:", error);
            Toast("error", "This Barcode is not matched", 1600);
        }
    };

    const handleLogout = () => {
        setAnchorEl(null);
        localStorage.removeItem("user");
        localStorage.removeItem("userToken");
        navigate("/sign-in");
        Toast("success", "Logout Successful", 1600);
    };

    const [anchorEl, setAnchorEl] = React.useState(null);
    const menuOpen = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const Results = () => (
        <div id="results" className="search-results">
            <br />
            <TableContainer
                component={Paper}
                sx={{
                    position: "static",
                    width: { xs: "100%", md: "60%" },
                    mx: "auto",
                }}
            >
                <Table sx={{ minWidth: "100%" }} aria-label="simple table">
                    {/*<TableHead>
                    <TableRow>
                      <TableCell>Dessert (100g serving)</TableCell>
                      <TableCell align="right">Calories</TableCell>
                    </TableRow>
                  </TableHead>*/}
                    <TableBody sx={{ border: "2px solid white" }}>
                        <TableRow
                            sx={{
                                ...TableStyle,
                                "&:last-child td, &:last-child th": {
                                    border: 0,
                                },
                            }}
                        >
                            <TableCell
                                sx={{ ...TableTextColor }}
                                component="th"
                                scope="row"
                            >
                                BTV Date
                            </TableCell>
                            <TableCell sx={{ ...TableTextColor }} align="right">
                                {loadedData[0]?.itv_date
                                    ? loadedData[0]?.itv_date
                                    : "-"}
                            </TableCell>
                        </TableRow>

                        {/* <TableRow
                            sx={{
                                ...TableStyle,
                                "&:last-child td, &:last-child th": {
                                    border: 0,
                                },
                            }}
                        >
                            <TableCell
                                sx={{ ...TableTextColor }}
                                component="th"
                                scope="row"
                            >
                                Supplier Name
                            </TableCell>
                            <TableCell sx={{ ...TableTextColor }} align="right">
                                {supplier}
                            </TableCell>
                        </TableRow> */}

                        <TableRow
                            sx={{
                                ...TableStyle,
                                "&:last-child td, &:last-child th": {
                                    border: 0,
                                },
                            }}
                        >
                            <TableCell
                                sx={{ ...TableTextColor }}
                                component="th"
                                scope="row"
                            >
                                BTV Number
                            </TableCell>
                            <TableCell sx={{ ...TableTextColor }} align="right">
                                {loadedData[0]?.itv_no
                                    ? loadedData[0]?.itv_no
                                    : "-"}
                            </TableCell>
                        </TableRow>

                        {/* <TableRow
                            sx={{
                                ...TableStyle,
                                "&:last-child td, &:last-child th": {
                                    border: 0,
                                },
                            }}
                        >
                            <TableCell
                                sx={{ ...TableTextColor }}
                                component="th"
                                scope="row"
                            >
                                Scanned barcode
                            </TableCell>
                            <TableCell align="right">
                                <Button
                                    variant="contained"
                                    size="small"
                                    sx={{
                                        "& .MuiButton-root": {
                                            padding: "5px !important",
                                        },
                                    }}
                                    onClick={() =>
                                        navigate("/QrCode", {
                                            state: {
                                                data: loadData,
                                                loadedData: loadedData,
                                                where: "pr",
                                            },
                                        })
                                    }

                                    // onClick={BarCodeValidator}
                                >
                                    Go
                                </Button>
                            </TableCell>
                        </TableRow> */}
                        <TableRow
                            sx={{
                                ...TableStyle,
                                "&:last-child td, &:last-child th": {
                                    border: 0,
                                },
                            }}
                        >
                            <TableCell
                                sx={{
                                    ...TableTextColor,
                                    pr: 0,
                                    color: "#fff !important",
                                }}
                                component="th"
                                scope="row"
                            >
                                <TextField
                                    sx={{
                                        width: "100%",
                                        border: 1,
                                        borderRadius: "2px",

                                        "& .MuiInputBase-input": {
                                            color: "white",
                                        },
                                    }}
                                    size="small"
                                    onKeyPress={(e) => {
                                        if (e.key === "Enter") {
                                            handlebarCodeChecker(
                                                e.target.value
                                            );
                                        }
                                    }}
                                />
                            </TableCell>
                            <TableCell align="right">
                                {/* <Button
                                    variant="contained"
                                    size="small"
                                    sx={{
                                        "& .MuiButton-root": {
                                            padding: "5px !important",
                                        },
                                    }}
                                    onClick={() =>
                                        navigate("/QrCode", {
                                            state: {
                                                data: loadData,
                                                loadedData: loadedData,
                                                where: "pr",
                                            },
                                        })
                                    }

                                    // onClick={BarCodeValidator}
                                >
                                    Go
                                </Button> */}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>

            {/*-----------------------------------Item Table View----------------------------*/}
            <TablesView loadedData={loadedData} />

            {/*-----------------------------------Status of GRN----------------------------*/}
            {/* <Box sx={{display:"flex",justifyContent:"center",color:"white",my:2,"& .MuiInputLabel-root":{
              color:"white !important",
            }}}>
            <TextField
            id="outlined-select-currency"
            select
            onChange={e =>{
              setGrnStatus(e.target.value)
              StatusChangeCall(e.target.value)
              }}
            InputProps={{
              style: { borderColor: "white" } 
            }}
            sx={{mt:1,
              borderColor:"white",
              color:"white",
              "& .MuiSelect-select":{
              borderColor:"white",
              p:1,
              outline : '1px solid white',
            },
            "& .MuiOutlinedInput-input":{
              padding:"5px 15px !important",
            },
            "& .MuiOutlinedInput-root":{
              color:grnStatusCOlor[GrnStatus],
              pr:0,
              textAlign: 'center',
              p:.8
            }
          }}
            label="Status"
            defaultValue={GrnStatus}
          >
            {status.map((option) => (
              <MenuItem key={option.value} sx={{color:grnStatusCOlor[option.value]}} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          </Box> */}
            <Box sx={{ textAlign: "center" }}>
                <Button
                    variant="outlined"
                    sx={{
                        mx: "auto",
                        width: "70%",
                        p: 1,
                        fontSize: "12px",
                        ...WhiteBorder,
                        color: "white",
                        backgroundColor: "black",
                    }}
                    onClick={StatusChangeCall}
                >
                    Save & Complete
                </Button>
            </Box>
            <br />

            {/*---------------------Final TAble-----------------------*/}

            <TableContainer
                component={Paper}
                sx={{
                    position: "static",
                    width: { xs: "100%", md: "30%" },
                    mx: "auto",
                }}
            >
                <Table sx={{ minWidth: "100%" }} aria-label="simple table">
                    {/*<TableHead>
      <TableRow>
        <TableCell>Dessert (100g serving)</TableCell>
        <TableCell align="right">Calories</TableCell>
      </TableRow>
</TableHead>*/}
                    <TableBody sx={{ border: "2px solid white" }}>
                        <TableRow
                            sx={{
                                ...TableStyle,
                                "&:last-child td, &:last-child th": {
                                    border: 0,
                                },
                            }}
                        >
                            <TableCell
                                sx={{ ...TableTextColor }}
                                component="th"
                                scope="row"
                            >
                                Total Quantity
                            </TableCell>

                            <TableCell
                                sx={{ ...TableTextColor }}
                                align="right"
                            >{`(${TotalCount.qty} + ${TotalCount.foc}) = ${
                                Number(TotalCount.qty) + Number(TotalCount.foc)
                            }`}</TableCell>
                        </TableRow>

                        <TableRow
                            sx={{
                                ...TableStyle,
                                "&:last-child td, &:last-child th": {
                                    border: 0,
                                },
                            }}
                        >
                            <TableCell
                                sx={{ ...TableTextColor }}
                                component="th"
                                scope="row"
                            >
                                Total Valid
                            </TableCell>
                            <TableCell sx={{ ...TableTextColor }} align="right">
                                {TotalCount.valid}
                            </TableCell>
                        </TableRow>

                        <TableRow
                            sx={{
                                ...TableStyle,
                                "&:last-child td, &:last-child th": {
                                    border: 0,
                                },
                            }}
                        >
                            <TableCell
                                sx={{ ...TableTextColor }}
                                component="th"
                                scope="row"
                            >
                                Total InValid
                            </TableCell>
                            <TableCell sx={{ ...TableTextColor }} align="right">
                                {TotalCount.invalid}
                            </TableCell>
                        </TableRow>

                        <TableRow
                            sx={{
                                ...TableStyle,
                                "&:last-child td, &:last-child th": {
                                    border: 0,
                                },
                            }}
                        >
                            <TableCell
                                sx={{ ...TableTextColor }}
                                component="th"
                                scope="row"
                            >
                                Not Attain
                            </TableCell>
                            <TableCell sx={{ ...TableTextColor }} align="right">
                                {0}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>

            <PrModelPopUp
                open={open}
                setOpen={setOpen}
                where={"pr"}
                data={popupData[0]}
                location={Location}
            />
            <br />
        </div>
    );

    return (
        <>
            <Box sx={{ width: "93%", mx: "auto", mt: 2 }}>
                <Box
                    sx={{
                        ...hCenter,
                        ...vCenter,
                        boxSizing: "border-box",
                        px: 2,
                        backgroundColor: "#001B3B",
                        py: "1.4rem",
                        borderRadius: 3,
                    }}
                >
                    <HeaderDrawer />
                    <Box>
                        <Typography
                            variant="h6"
                            display="block"
                            sx={{
                                ...colorWhite,
                                fontWeight: 600,
                                my: 0,
                                py: 0,
                                lineHeight: "1rem ",
                            }}
                        >
                            BTV Picking
                        </Typography>
                        <Typography
                            variant="span"
                            display="block"
                            sx={{
                                ...colorWhite,
                                my: 0,
                                py: 0,
                                fontSize: "12px",
                            }}
                        ></Typography>
                    </Box>

                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "0.5rem",
                        }}
                    >
                        <NotificationsNoneIcon sx={{ color: textWhite }} />
                        <>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    textAlign: "center",
                                }}
                            >
                                <Tooltip title="Account settings">
                                    <IconButton
                                        onClick={handleClick}
                                        size="small"
                                        aria-controls={
                                            menuOpen
                                                ? "account-menu"
                                                : undefined
                                        }
                                        aria-haspopup="true"
                                        aria-expanded={
                                            menuOpen ? "true" : undefined
                                        }
                                    >
                                        <Avatar sx={{ width: 22, height: 22 }}>
                                            {user["name"][0].toUpperCase()}
                                        </Avatar>
                                    </IconButton>
                                </Tooltip>
                            </Box>
                            <Menu
                                anchorEl={anchorEl}
                                id="account-menu"
                                open={menuOpen}
                                onClose={handleClose}
                                onClick={handleClose}
                                PaperProps={{
                                    elevation: 0,
                                    sx: {
                                        overflow: "visible",
                                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                        mt: 1.5,
                                        "& .MuiAvatar-root": {
                                            width: 22,
                                            height: 22,
                                            ml: -0.5,
                                            mr: 1,
                                        },
                                        "&::before": {
                                            content: '""',
                                            display: "block",
                                            position: "absolute",
                                            top: 0,
                                            right: 14,
                                            width: 10,
                                            height: 10,
                                            bgcolor: "background.paper",
                                            transform:
                                                "translateY(-50%) rotate(45deg)",
                                            zIndex: 0,
                                        },
                                    },
                                }}
                                transformOrigin={{
                                    horizontal: "right",
                                    vertical: "top",
                                }}
                                anchorOrigin={{
                                    horizontal: "right",
                                    vertical: "bottom",
                                }}
                            >
                                <MenuItem onClick={handleLogout}>
                                    <ListItemIcon>
                                        <Logout fontSize="small" />
                                    </ListItemIcon>
                                    Logout
                                </MenuItem>
                            </Menu>
                        </>
                    </Box>
                </Box>
                <div>
                    {/* <input type="submit" value="Load" onClick={onClick} /> */}
                    <br />

                    <Box
                        sx={{
                            textAlign: "center",
                            width: { xs: "80%", md: "35%" },
                            mx: "auto",
                        }}
                    >
                        <Box sx={{ textAlign: "start", mb: 1 }}>
                            <Typography
                                variant="span"
                                display="block"
                                sx={{
                                    color: "white",
                                    my: 0,
                                    py: 0,
                                    fontSize: "12px",
                                }}
                            >
                                Bar Code number
                            </Typography>
                            <TextField
                                sx={{
                                    position: "static",
                                    backgroundColor: "white",
                                    fontSize: "12px",
                                    borderRadius: 1,
                                    width: "100%",
                                }}
                                id="outlined-adornment-password"
                                size="small"
                                placeholder="Bar Code Number"
                                name="bar_code"
                                value={loadData.bar_code}
                                onChange={(e) => loadDatahandler(e)}
                            />
                        </Box>
                        <Box sx={{ textAlign: "start" }}>
                            <Typography
                                variant="span"
                                display="block"
                                sx={{
                                    color: "white",
                                    my: 0,
                                    py: 0,
                                    fontSize: "12px",
                                }}
                            >
                                Warehouse number
                            </Typography>
                            <TextField
                                sx={{
                                    position: "static",
                                    backgroundColor: "white",
                                    fontSize: "12px",
                                    borderRadius: 1,
                                    width: "100%",
                                }}
                                id="outlined-adornment-password"
                                size="small"
                                placeholder="Warehouse Number"
                                name="wh_cd"
                                value={loadData.wh_cd}
                                onChange={(e) => loadDatahandler(e)}
                            />
                        </Box>

                        <Box sx={{ textAlign: "start", mt: 1 }}>
                            <Typography
                                variant="span"
                                display="block"
                                sx={{
                                    ...colorWhite,
                                    my: 0,
                                    py: 0,
                                    fontSize: "12px",
                                }}
                            >
                                BTV Number
                            </Typography>
                            <TextField
                                sx={{
                                    backgroundColor: "white",
                                    fontSize: "12px",
                                    borderRadius: 1,
                                    width: "100%",
                                    position: "static",
                                }}
                                id="outlined-adornment-password"
                                size="small"
                                placeholder="BTV Number"
                                name="itv_no"
                                value={loadData.itv_no}
                                onChange={(e) => loadDatahandler(e)}
                            />
                        </Box>
                    </Box>

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            mt: 2,
                        }}
                    >
                        <Buttons
                            variant="primary"
                            type="submit"
                            disabled={
                                loadData.itv_no != "" &&
                                loadData.itv_no != " " &&
                                loadData.wh_cd != "" &&
                                loadData.wh_cd != ""
                                    ? false
                                    : true
                            }
                            onClick={loadCall}
                        >
                            Load
                        </Buttons>
                    </Box>

                    <br />

                    <br />

                    {Loading && (
                        <Box sx={{ width: "100%" }}>
                            <LinearProgress />
                        </Box>
                    )}

                    {intialTime && !Loading && loadedData.length > 0 && (
                        <Results />
                    )}

                    {intialTime && !Loading && loadedData.length <= 0 && (
                        <Box>
                            <Typography
                                sx={{
                                    color: "white",
                                    fontWeight: "bold",
                                    textAlign: "center",
                                }}
                            >
                                No Data Found
                            </Typography>
                        </Box>
                    )}
                </div>
            </Box>
        </>
    );
}

export default Picking;
