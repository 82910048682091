import React from "react";
import PropTypes from "prop-types";
import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { grey } from "@mui/material/colors";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import TableViewIcon from "@mui/icons-material/TableView";
import BarChartIcon from "@mui/icons-material/BarChart";
import { useNavigate } from "react-router-dom";
//Color
import {
    TextBlue,
    darkBlue,
    overDarkBlue,
} from "../../../styled-Component/colors";
import { vCenter, colorWhite } from "../../../styled-Component/elements";

const drawerBleeding = 56;

const Root = styled("div")(({ theme }) => ({
    backgroundColor: "black",
}));

const StyledBox = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.mode === "light" ? overDarkBlue : grey[800],
}));

const Puller = styled("div")(({ theme }) => ({
    width: 70,
    height: 6,
    backgroundColor: theme.palette.mode === "light" ? TextBlue : grey[900],
    borderRadius: 3,
    position: "absolute",
    top: 8,
    left: "calc(50% - 35px)",
}));

function SwipeableEdgeDrawer(props) {
    const { window, open, setOpen, selected, itemList, invoiceList } =
        props;

    const toggleDrawer = () => () => {
        setOpen(!open);
    };

    // This is used only for the example
    const container =
        window !== undefined ? () => window().document.body : undefined;
    const navigate = useNavigate();
    return (
        <Root>
            <CssBaseline />
            {
                <Global
                    styles={{
                        ".MuiDrawer-root > .MuiPaper-root": {
                            height: `calc(70% - ${drawerBleeding}px)`,
                            overflow: "visible",
                            borderTopLeftRadius: "40px",
                            borderTopRightRadius: "40px",
                            borderTop: "2px solid white",
                            backgroundColor: overDarkBlue,
                        },
                    }}
                />
            }

            <SwipeableDrawer
                container={container}
                anchor="bottom"
                open={open}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
                swipeAreaWidth={drawerBleeding}
                disableSwipeToOpen={false}
                ModalProps={{
                    keepMounted: true,
                }}
            >
                <StyledBox
                    sx={{
                        position: "absolute",
                        top: 0,
                        borderTopLeftRadius: 120,
                        borderTopRightRadius: 120,
                        visibility: "visible",
                        right: 0,
                        left: 0,
                    }}
                >
                    <Puller />
                    <Typography
                        sx={{
                            p: 2,
                            color: "white",
                            textAlign: "center",
                            mt: 3,
                        }}
                    >
                        Select View type
                    </Typography>
                    <Box>
                        <Box
                            onClick={() =>
                                navigate("/TablePage", {
                                    state: {
                                        data: selected,
                                        where: "invoiceList",
                                        itemList,
                                        invoiceList,
                                    },
                                })

                                
                            }
                            sx={{
                                borderRadius: 2,
                                backgroundColor: darkBlue,
                                textAlign: "center",
                                width: "50%",
                                mx: "auto",
                                padding: 1,
                            }}
                        >
                            <TableViewIcon
                                sx={{ lineHeight: ".8rem", ...colorWhite }}
                            />
                            <Typography
                                component={"span"}
                                sx={{
                                    display: "block",
                                    ...colorWhite,
                                    lineHeight: "1rem",
                                }}
                            >
                                Table
                            </Typography>
                        </Box>

                        <Box
                            onClick={() => navigate("/GraphPage")}
                            sx={{
                                borderRadius: 2,
                                backgroundColor: darkBlue,
                                textAlign: "center",
                                width: "50%",
                                mx: "auto",
                                padding: 1,
                                mt: 2,
                            }}
                        >
                            <BarChartIcon
                                sx={{ lineHeight: ".8rem", ...colorWhite }}
                            />
                            <Typography
                                component={"span"}
                                sx={{
                                    display: "block",
                                    ...colorWhite,
                                    lineHeight: "1rem",
                                }}
                            >
                                Graph
                            </Typography>
                        </Box>
                    </Box>
                </StyledBox>
            </SwipeableDrawer>
        </Root>
    );
}

SwipeableEdgeDrawer.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

export default SwipeableEdgeDrawer;
