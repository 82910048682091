import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import React, { useEffect, useState } from "react";
import { useNavigate,useLocation } from "react-router-dom";
import axios from "axios";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowBackIos';
import { Url } from "../../Axios/Axios";



import {InputLabel,FormControl} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';

import Select from '@mui/material/Select';
import { Toast } from "../../helper/toaster";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {containerStyle} from '../../styled-Component/elements'
import {lazy,Suspense} from 'react'
import {userrole} from '../../helper/token'
import {ApiCall} from '../../helper/Apicall'


const Menulists = lazy(()=>import('./modules/menulist'))

export default function Register() {
    const navigate = useNavigate();
    const location = useLocation()
    const [loading,setLoading] = useState(false)
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const roleINterface = {
        admin:"user",
        superadmin:"admin"
    }

    
    let roleParse = userrole()

    const role = roleParse ? roleINterface[roleParse] : ""
    

    const [loginData, setLoginData] = useState({
        name:"",
        email: "",
        password: "",
        userid:"",
        confirm_password: "",
        menuslist :[],
        role:role
    });
    const [loginDataError, setLoginDataErr] = useState({
        name:"",
        email: "",
        password: "",
        userid:"",
        confirm_password: "",
        menuslist :"", 
    });

    const handleChange = (e) => {
        setLoginDataErr({
            name:"",
            email: "",
            password: "",
            userid:"",
            confirm_password: "",
            menuslist :"",
            
        })

        setLoginData({ ...loginData, [e.target.name]: e.target.value });
    };


    


    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleClickShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const handleMouseDownConfirmPassword = (event) => {
        event.preventDefault();
    };
// const [loading,setLoading] = useState(false)

    const registerCall = async()=>{
        setLoading(true)
        let {password,email,name,role,userid,menuslist} = loginData
        let bodyTag = {name,
            password,email,userid : Number(userid),name,role,path_access:roleParse =="admin"?  menuslist.join() : 0}
            
        const {data,error} = await ApiCall(`register`,"post",bodyTag)
        if(data){
//   console.log(data,"lists")
//   setUsersList(data)
Toast("success","Registered Successfully")
navigate(`/credentials`)

setLoading(false)
        }else{
          
          Toast("error",error?.data?.error?.userid?.[0] ? error?.data?.error?.userid?.[0] : "oops Error on 1001")
          setLoading(false)
        }
      }
  
      
  
      
  

    const handleSubmit = async (e) => {
        e.preventDefault();

        const errors = {...loginDataError}
        
        const {confirm_password,password,email,name,menuslist,userid} = loginData
        if(!name){
            errors.name = "Name is required"
        }

        if(!userid){
            errors.userid = "userid is required"
        }else if(isNaN(userid)){
errors.userid = "userid must be numeric"
        }else if(userid.length <4){
errors.userid = "userid must be at least 4 number"
        }
        if(email){if(!email || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)){
            errors.email = "Invalid email address"
        }}
        if(!password || password.length<3){
            errors.password = "Password should be at least 6 characters"
        }
        if(!confirm_password || confirm_password !== password){
            errors.confirm_password = "Password and confirm password should be same"
        }

if(roleParse =="admin"){
    if(loginData.menuslist.length <= 0 ){
        errors.menuslist = "menuslist is required"
    }
}

        let hasError = false
        Object.keys(errors).forEach(key => {
            if(errors[key]){
                hasError = true
            }
        })

        

        if(hasError){
            setLoginDataErr(errors)
        }else{
            registerCall()
            
            
            
        
            
        }
    };

    return (
<Box sx={{...containerStyle,maxHeight:"80vh",overflow:"scroll"}}>
<Box sx={{display:"flex",justifyContent:"flex-end"}}> <Button
                variant="contained"
                
                startIcon={<ArrowForwardIosIcon />}
                onClick={() => navigate(`/credentials`)}
                size="small"
                sx={{ mb: 0 }}
            >
                Back
            </Button>
            </Box>
        <Container component="main" maxWidth="xs">
            
            <Box
                sx={{
                    marginTop: 2,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    backgroundColor: "rgb(255, 255, 255)",
                    borderRadius: "1rem",
                    mx: "auto",
                }}
            >

                <Typography component="h1" variant="h6" sx={{ mt: 1,textTransform:"capitalize" }}>
                    Add {role} 
                </Typography>
                <Box
                    component="form"
                    onSubmit={handleSubmit}
                    noValidate
                    sx={{
                        p: 1,
                        mt: 1,
                    }}
                >
                    
                    <TextField
                        size="small"
                        
                        required
                        fullWidth
                        id="userid"
                        label="UserId"
                        name="userid"
                        value={loginData.userid}
                        error={loginDataError.userid ? true : false}
                        helperText={loginDataError.userid}
                        
                        onChange={(e)=>{
if(!isNaN(e.target.value) ){
    handleChange(e)
}
                        }}
                        autoFocus
                    />

                    <TextField
                        size="small"
                        required
                        fullWidth
                        sx={{ mt: 3 }}
                        id="name"
                        label="Name"
                        name="name"
                        error={loginDataError.name ? true : false}
                        helperText={loginDataError.name}
                        onChange={handleChange}
                        autoFocus
                    />
                    <TextField
                        size="small"
                        sx={{ mt: 3 }}
                        
                        fullWidth
                        id="email"
                        label="Email"
                        name="email"
                        autoComplete="email"
                        error={loginDataError.email ? true : false}
                        helperText={loginDataError.email}
                        onChange={handleChange}
                        autoFocus
                    />
                    
                    <TextField
                        size="small"
                        required
                        fullWidth
                        sx={{ mt: 3 }}
                        name="password"
                        label="Password"
                        type={showPassword ? "text" : "password"}
                        error={loginDataError.password ? true : false}
                        helperText={loginDataError.password}
                        onChange={handleChange}
                        id="password"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <TextField
                        size="small"
                        required
                        fullWidth
                        sx={{ mt: 3 }}
                        name="confirm_password"
                        label="Confirm Password"
                        type={showConfirmPassword ? "text" : "password"}
                        error={loginDataError.confirm_password ? true : false}
                        helperText={loginDataError.confirm_password}
                        onChange={handleChange}
                        id="password"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowConfirmPassword}
                                        onMouseDown={handleMouseDownConfirmPassword}
                                        edge="end"
                                    >
                                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />



{roleParse == "admin" && <Suspense fallback={<p>loading ...</p>}><Menulists from={"register"} roleParse={roleParse} menu={loginData} setLoginData={setLoginData} loginDataError={loginDataError}   setLoginDataErr={setLoginDataErr} /></Suspense>}

                    <Box sx={{ textAlign: "center" }}>
                        <Button
                            type="submit"
                            variant="contained"
                            disabled={loading}
                            onClick={handleSubmit}
                            sx={{
                                mt: 3,
                                mb: 2,
                                fontSize: "14px",
                                padding: "5px 10px 3px 10px",
                            }}
                        >
                            {loading ? "Processing...": "Register"}
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Container>
        </Box>
    );
}

