export const token = () =>
  localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user")).token
    : null;

export const userrole = () =>
  localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user")).role
    : null;
export const higherAuth = () =>
  ["superadmin", "admin"].includes(
    JSON.parse(localStorage.getItem("user")).role
  )
    ? true
    : false;
export const path_access = () =>
  localStorage.getItem("user")
    ? ["superadmin", "admin"].includes(
        JSON.parse(localStorage.getItem("user"))?.role
      )
      ? "all"
      : JSON.parse(localStorage.getItem("user"))?.path_access
    : "";
