import React, { useRef, useEffect, useState, lazy } from "react";
import { Html5Qrcode } from "html5-qrcode";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { Url } from "../../Axios/Axios";
import PrModelPopUp from "../QrCode/helper/popupMdelForPR";
import { Box, Button, Typography } from "@mui/material";
import { Toast } from "../../helper/toaster";

const BarcodeReader = () => {
    const videoRef = useRef(null);

    const [open, setOpen] = useState(false);
    const [popupData, setPopupData] = useState([]);
    const [hasScannedSuccessfully, setHasScannedSuccessfully] = useState(false);
    const [hasCameraPermission, setHasCameraPermission] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const userJSON = localStorage.getItem("user");
    const user = userJSON && JSON.parse(userJSON);

    const locationInit = useLocation();
    const navigate = useNavigate();

    const dataForBarCode = locationInit.state.data;
    const loadedData = locationInit.state.loadedData;
    const where = locationInit.state.where;

    useEffect(() => {
        const qrCode = new Html5Qrcode("reader");

        const askForCameraPermission = async () => {
            try {
                setIsLoading(true);
                const permission = await navigator.mediaDevices.getUserMedia({
                    video: true,
                });

                if (permission) {
                    setHasCameraPermission(true);
                    setIsLoading(false); // Update here
                    startScanner();
                } else {
                    setHasCameraPermission(false);
                    setIsLoading(false); // Update here
                }
            } catch (error) {
                console.error("Error requesting camera permission:", error);
                setHasCameraPermission(false);
                setIsLoading(false);
            }
        };

        const startScanner = async () => {
            try {
                await qrCode.start(
                    {
                        facingMode: "environment",
                    },
                    {
                        fps: 10,
                        qrbox: 250,
                    },
                    (decodedText) => {
                        BarCodeValidator(decodedText);
                        setHasScannedSuccessfully(true);
                    }
                    // (errorMessage) => {
                    //     // Handle errors
                    //     console.error(errorMessage);
                    // }
                );
            } catch (error) {
                console.error("Failed to start scanner:", error);
            }
        };

        askForCameraPermission();

        return () => {
            try {
                qrCode.stop(); // Attempt to stop the scanner, catching error if no stream is active
            } catch (error) {
                // Ignore error if stream is not active (camera permission denied)
                console.log("Ignoring error: No camera stream to stop");
            }
        };
    }, []);

    const BarCodeValidator = async (validBarNumber) => {
        try {
            // const headers = {
            //     Accept: "application/json",
            //     "Content-Type": "application/json",
            //     Authorization: `Bearer ${user.token}`,
            // };
            // const response = await axios.post(
            //     `${Url}check-barcode`,
            //     { barcode: validBarNumber },
            //     { headers }
            // );

            const token = localStorage.getItem("userToken");
            if (!token) {
                throw new Error("User token is not available");
            }

            const response = await fetch(`${Url}check-barcode`, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({ barcode: validBarNumber }),
            }).then((response) => response.json());


            const newItem = loadedData?.filter(
                (item) => item.item_code === response.data.item_code
            );

            if (newItem.length > 0) {
                setPopupData(newItem);
                setOpen(true);
            } else {
                setOpen(false);
                Toast("error", "This Barcode is not matched", 1600);
            }
        } catch (error) {
            console.error("Error during barcode validation:", error);
            Toast("error", "This Barcode is not matched", 1600);
        }
    };

    const goBack = () => {
        navigate(-1);
    };

    return (
        <div>
            <div id="reader">
                <video ref={videoRef} style={{ width: "100%" }} />
            </div>
            {hasCameraPermission === false && (
                <Typography
                    component="h2"
                    variant="body2"
                    sx={{ textAlign: "center", color: "white" }}
                >
                    No camera permission
                </Typography>
            )}
            {isLoading && (
                <Typography
                    component="h2"
                    variant="body2"
                    sx={{ textAlign: "center", color: "white" }}
                >
                    Loading...
                </Typography>
            )}
            <Box sx={{ textAlign: "center", mt: 2 }}>
                <Button onClick={goBack}>Back</Button>
            </Box>
            {popupData.length > 0 && (
                <PrModelPopUp
                    open={open}
                    setOpen={setOpen}
                    where={"pr"}
                    data={popupData[0]}
                    location={dataForBarCode}
                />
            )}
        </div>
    );
};

export default BarcodeReader;
