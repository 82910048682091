
import axios from 'axios'
import {Url} from '../Axios/Axios'


export async function ApiCall(path,method,body={}){


try {
    const response = await axios({
      method: method,
      url: `${Url}${path}`,
      data: body,
        headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        "Authorization":`Bearer ${localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).token : ""} `
        // "Authorization"localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).token : ""
    },
    });
    return ({
        data : response?.data?.data,
        error:""
    }) 
  } catch (error) {
    return ({
        data : "",
        error:error.response
    }) 
  }

}


