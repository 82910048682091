import { Box } from '@mui/material'
import React,{Suspense,lazy,startTransition} from 'react'
import {Loader} from '../../helper/loader'
import UsersLists from './components/usersLists'
import Headers from '../headers/index'



function UsersPage() {
  return (<>
      <Headers/>
     


 <UsersLists/>

    
    </>
  )

}

export default UsersPage
