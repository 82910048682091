import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableHeadIssue } from "../../styled-Component/elements";

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}

const rows = [createData("apple", 159, 6.0, 24, 4.0)];

const TableBodyStyle = {
    backgroundColor: "black",
    padding: "10px",
    fontSize: "12px",
    color: "lightgrey",
};

// const header = ["Batch No", "Expiry", "Qty", "FOC qty"];
const header = ["Batch No", "Expiry", "Qty"];

export default function BasicTable({ data }) {
    // console.log(data);
    return (
        <TableContainer component={Paper}>
            <Table sx={{}} aria-label="simple table">
                <TableHead sx={{ ...TableHeadIssue, border: "0px !important" }}>
                    <TableRow
                        sx={{
                            backgroundColor: "transparent",
                            border: "0px !important",
                        }}
                    >
                        {header.map((item, index) => {
                            return (
                                <TableCell
                                    key={index}
                                    sx={{
                                        border: 0,
                                        backgroundColor: "black",
                                        padding: "10px",
                                        fontSize: "10.5px",
                                        color: "white",
                                    }}
                                    align="center"
                                >
                                    {item}
                                </TableCell>
                            );
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow
                        sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                        }}
                    >
                        <TableCell sx={{ ...TableBodyStyle }} align="center">
                            {data?.batch_no ? data?.batch_no : "-"}
                        </TableCell>
                        <TableCell sx={{ ...TableBodyStyle }} align="center">
                            {data?.exp_date ? data?.exp_date : "-"}
                        </TableCell>
                        <TableCell sx={{ ...TableBodyStyle }} align="center">
                            {data?.qty ? data?.qty : 0}
                        </TableCell>
                        {/* <TableCell sx={{ ...TableBodyStyle }} align="center">
                            {data?.foc_qty ? data?.foc_qty : 0}
                        </TableCell> */}
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
}
