
import {userrole} from './token'

//Icons
import {

    SupervisorAccount,
        Search,
    ArrowForward,
    AccountCircle,
    MailOutline,
    ArrowForwardIos,
    
    LocalTaxi,
    QrCode2,
    AppRegistration,
    MoveToInbox,
    Outbound,
    Settings,
    CompareArrows,
    SyncAlt,
    AdminPanelSettings,
    PermIdentity,
    ShoppingCart,
    
    DriveFolderUpload
} from "@mui/icons-material";

export const paths = [
  
  {refId:1,id:"1001",Content:"Purchase Receiving",to:"/receiving",Logo:ShoppingCart},
  {refId:1,id:"1002",Content:"Placer",to:"/dashboard",Logo:PermIdentity},
  {refId:2,id:"2002",Content:"Collector",to:"/dashboard",Logo:ShoppingCart},
  {refId:2,id:"2001",Content:"Btv Picking",to:"/picking",Logo:LocalTaxi},
  {refId:3,id:"3001",Content:"Product Finder",to:"/product-finder",Logo:Search},
  {refId:3,id:"3002",Content:"QR Code Scanner",to:"/qr",Logo:QrCode2},
  {refId:4,id:"4001",Content:`User Setup`,to:"/credentials",Logo:AppRegistration},
]




let All = ["superadmin","admin","user"]
let Admins =["superadmin","admin"]

export const MainMenu = [
    {id:1,Content:"InBound",access:All,Logo:MoveToInbox},
    {id:2,Content:"OutBound",access:All,Logo:DriveFolderUpload},
    {id:3,Content:"Utilities",access:All,Logo:Settings},
    {id:4,Content:"Setup",access:Admins,Logo:SupervisorAccount},
    
  ]


  