import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    value: 0,

    items: [],
    invoice: [],
};

export const counterSlice = createSlice({
    name: "counter",
    initialState,
    reducers: {
        AddItem: (state, action) => {
            state.items = action.payload;
        },
        AddInvoice: (state, action) => {
            state.invoice = action.payload;
        },
    },
});

export const { AddInvoice, AddItem } = counterSlice.actions;

export default counterSlice.reducer;
