import React from "react";
import { Navigate, Outlet } from "react-router-dom";


export default function ProtectedRoutes() {

    

    const user = JSON.parse(localStorage.getItem("user"));


    if (!user && user === null) {
        return <Navigate to="/sign-in" replace />;
    } else {
        return <Outlet />;
    }
}
