import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import {Box,Typography,Button} from '@mui/material';
import { useState } from 'react';
import {NavButton} from '../../../styled-Component/elements'
import { useNavigate } from 'react-router-dom';
import {ApiCall} from '../../../helper/Apicall'
import {userrole} from '../../../helper/token'
import {No_Data} from '../../../helper/Nodata'
import {Loader} from '../../../helper/loader'
import Update  from '../../Auth/update'

export default function AlignItemsList() {

  const navigate = useNavigate()

    let [usersList,setUsersList] = useState([])
    let [loading,setLoading] = useState(false)
    let [selecteduser,setSelecteduser] = useState("")
    let [updatepage,setupdatepage] = useState(false)
    let [changes,setChanges] = useState(false)

    const Role = userrole()


    const getAlluses = async()=>{
      setLoading(true)
      const resp = await ApiCall(`users?role=${Role}`,"get")

      if(resp.data){


if(Role =="superadmin"){
  setUsersList(resp.data.filter(itm => itm.role !="superadmin"))
}else if(Role =="admin"){
  setUsersList(resp.data.filter(itm => itm.role =="user"))
}



setLoading(false)
      }else if(resp.error){
        console.log(resp.error,"error")
        setLoading(false)
      }
    }

    

    React.useEffect(()=>{

      getAlluses()
    
    },[])

    React.useEffect(()=>{

      getAlluses()
    
    },[changes])

    

const registerCall = ()=>{
  navigate("/register")
}

const UpdateCall = (data)=>{
  setupdatepage(true)
  setSelecteduser(data)
}

const backCallFromUpdate = ()=>{
  setupdatepage(false)
  setSelecteduser("")
}



  return (<Box>

{(selecteduser && updatepage ) && <Update data={selecteduser} backCallFromUpdatee={backCallFromUpdate} setChanges={setChanges}/>}
{!selecteduser && !updatepage && <> 
    <Box sx={{display:"flex",justifyContent:"flex-end",my:2}}>
      <Button onClick={registerCall}sx={{...NavButton,mr:4}} variant="contained" size='small'>Add Credentials</Button>
      </Box>

      {/* --------------------User List------------------------ */}
      <Box sx={{ mt: 2,maxHeight:"78vh",overflow:"scroll"}}>
    <List sx={{width: "95%", mx: "auto"  }}>
     {(!loading &&  usersList.length > 0) && usersList.map((itm,index)=>{
        return <Box onClick={()=>UpdateCall(itm)} key={index+itm.name} sx={{border:"1px solid white",mb:1.5,borderRadius:3,cursor:"pointer"}}>
        <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center",width:"97%",mx:"auto"}}>

        <ListItem alignItems="center" className='morgana' sx={{padding:"4px 10px !important"}}>
        
          <Avatar alt={itm.name} src="/static/images/avatar/1.jpg" sx={{width:35,height:35,mt:.5,mr:2}} />
        <ListItemText
        sx={{color:"white"}}
          primary={itm.name}
          secondary={
              <Typography
                component="span"
                variant="body2"
                sx={{ color: 'white', display: 'inline' }}
              >
                {itm.email}
              </Typography>
          }
        />
      </ListItem>
      
      <Typography variant='span' component={"span"} sx={{color:"white",width:"40%",textAlign:"end"}}>{itm.role ? itm.role  : "-"}</Typography>
      
      </Box>  
        </Box>
     })
      }



    {/* loading */}
    {loading && <Loader/> }

    {/* No Data  */}
    {(!loading &&  usersList.length <= 0) && <No_Data/>}  
    
    </List>
    </Box>
    </>}
    </Box>
  );
}
