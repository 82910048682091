const BarCodeInputValidator = (barcode) => {
    // Check if the barcode length is more than 15 and starts with '01'
    if (barcode.length > 15 && barcode.startsWith("01")) {
        // Extract specific part of the barcode
        barcode = barcode.substring(2, 16); // Extract from position 2 to 15 (14 characters)
    }

    return barcode;
};

export default BarCodeInputValidator;
