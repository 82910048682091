import {
  Box,
  Typography,
  Button,
  Grid,
  Paper,
  LinearProgress,
  MenuItem,
  TextField,
  Tooltip,
  Avatar,
  Menu,
  ListItemIcon,
} from "@mui/material";
import React, { lazy, useEffect, useState } from "react";
import {
  hCenter,
  vCenter,
  colorWhite,
  pointer,
} from "../../styled-Component/elements";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { textWhite, TextBlue } from "../../styled-Component/colors";
import { useNavigate, useLocation } from "react-router-dom";
// import TablesView from "./Table";
import "bootstrap/dist/css/bootstrap.css";
import Form from "react-bootstrap/Form";
import Buttons from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tabled from "react-bootstrap/Table";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import axios from "../../Axios/Axios";
import { Toast } from "../../helper/toaster";
import { BgBlackImage } from "../../styled-Component/colors";
import { Url } from "../../Axios/Axios";
import { WhiteBorder } from "../../styled-Component/elements";
import { Virtuoso } from "react-virtuoso";
import { Logout } from "@mui/icons-material";

import BarCodeInputValidator from "../../helper/barCodeValidator";
import HeaderDrawer from "../../helper/HeaderDrawer";
const PrModelPopUp = lazy(() => import("../QrCode/helper/popupMdelForPR"));

function ProdcutFinder() {
  const [showResults, setShowResults] = React.useState(false);
  const [intialTime, setintialTime] = React.useState(false);
  const [Loading, setLoading] = React.useState(false);
  const [loadedData, setLoadedData] = React.useState(null);
  const [barCodeNumberValidate, setBarCodeNumberValidate] =
    React.useState(null);

  const userJSON = localStorage.getItem("user");
  const user = userJSON && JSON.parse(userJSON);

  const [loadData, setLoad] = useState({
    item: "",
  });

  const navigate = useNavigate();
  const Location = useLocation();

  const loadDatahandler = (e) => {
    setLoad((prev) => ({
      ...prev,
      [e.target.name]: e.target.value.trim(),
    }));
  };

  const handleOnKeyPressBarCode = (e) => {
    if (e.key === "Enter") {
      axiosCall();
    }
  };

  const axiosCall = async () => {
    setShowResults(true);
    setintialTime(true);
    setLoading(true);

    try {
      const token = localStorage.getItem("userToken");
      if (!token) {
        throw new Error("User token is not available");
      }
      const validBarNumber = BarCodeInputValidator(loadData?.item);

      const response = await fetch(
        `${Url}find-product?barcode=${validBarNumber}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ item: validBarNumber }),
        }
      );

      const done = await response.json();
      console.log(done.data);
      setLoadedData(done.data);
      setLoading(false);
      setBarCodeNumberValidate(validBarNumber);
    } catch (error) {
      console.log(error.response);
      setLoadedData(null);
      setLoading(false);
      Toast("error", "Invalid Number", 1600);
    }
  };

  const handleClearLoad = () => {
    setLoad({ item: "" });
    setLoadedData(null);
    setintialTime(false);
    setShowResults(false);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuOpen = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    setAnchorEl(null);
    localStorage.removeItem("user");
    localStorage.removeItem("userToken");
    navigate("/sign-in");
    Toast("success", "Logout Successful", 1600);
  };
  const TableStyle = {
    backgroundColor: BgBlackImage,
    color: "white !important",
  };
  const TableTextColor = { color: "white", fontSize: "17px" };

  const Results = () => (
    <div id="results" className="search-results">
      <TableContainer
        component={Paper}
        sx={{
          position: "static",
          width: { xs: "100%", md: "50%" },
          mx: "auto",
          mt: 2,
        }}
      >
        <Table sx={{ minWidth: "100%" }} aria-label="simple table">
          <TableBody sx={{ border: "2px solid white" }}>
            <TableRow
              sx={{
                ...TableStyle,
                "&:last-child td, &:last-child th": {
                  border: 0,
                },
              }}
            >
              <TableCell
                sx={{ ...TableTextColor }}
                component="th"
                scope="row"
                colSpan={2}
              >
                <Typography component="span" fontWeight={600} variant="body1">
                  Item Code:
                </Typography>{" "}
                <br />
                <Typography component="span" variant="body2">
                  {loadedData?.item_code ? loadedData?.item_code : ""}
                </Typography>{" "}
                <br />
                <br />
                <Typography component="span" fontWeight={600} variant="body1">
                  Item Name:
                </Typography>{" "}
                <br />
                <Typography component="span" variant="body2">
                  {loadedData?.item_name ? loadedData?.item_name : "-"}
                </Typography>
                <br />
                <br />
                <Typography component="span" fontWeight={600} variant="body1">
                  Bar Code:
                </Typography>{" "}
                <br />
                <Typography component="span" variant="body2">
                  {barCodeNumberValidate ? barCodeNumberValidate : "-"}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );

  return (
    <>
      <Box sx={{ width: "93%", mx: "auto", mt: 2 }}>
        <Box
          sx={{
            ...hCenter,
            ...vCenter,
            boxSizing: "border-box",
            px: 2,
            backgroundColor: "#001B3B",
            py: "1.4rem",
            borderRadius: 3,
          }}
        >
          <HeaderDrawer />
          <Box>
            <Typography
              variant="h6"
              display="block"
              sx={{
                ...colorWhite,
                fontWeight: 600,
                my: 0,
                py: 0,
                lineHeight: "1rem ",
              }}
            >
              Product Finder
            </Typography>
            <Typography
              variant="span"
              display="block"
              sx={{
                ...colorWhite,
                my: 0,
                py: 0,
                fontSize: "12px",
              }}
            ></Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
            }}
          >
            <NotificationsNoneIcon sx={{ color: textWhite }} />
            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <Tooltip title="Account settings">
                  <IconButton
                    onClick={handleClick}
                    size="small"
                    aria-controls={menuOpen ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={menuOpen ? "true" : undefined}
                  >
                    <Avatar sx={{ width: 22, height: 22 }}>
                      {user["name"][0].toUpperCase()}
                    </Avatar>
                  </IconButton>
                </Tooltip>
              </Box>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={menuOpen}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 22,
                      height: 22,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&::before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{
                  horizontal: "right",
                  vertical: "top",
                }}
                anchorOrigin={{
                  horizontal: "right",
                  vertical: "bottom",
                }}
              >
                <MenuItem onClick={handleLogout}>
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  Logout
                </MenuItem>
              </Menu>
            </>
          </Box>
        </Box>
        <div>
          <br />

          <Box
            sx={{
              textAlign: "center",
              width: { xs: "80%", md: "30%" },
              mx: "auto",
            }}
          >
            <Box sx={{ textAlign: "start" }}>
              <Typography
                variant="span"
                display="block"
                sx={{
                  color: "white",
                  my: 0,
                  py: 0,
                  fontSize: "12px",
                }}
              >
                Product Item Code
              </Typography>
              <TextField
                sx={{
                  position: "static",
                  backgroundColor: "white",
                  fontSize: "12px",
                  borderRadius: 1,
                  width: "100%",
                }}
                id="outlined-adornment-password"
                size="small"
                placeholder="Product Item Code"
                name="item"
                autoFocus
                value={loadData.item}
                onChange={(e) => loadDatahandler(e)}
                onKeyPress={(e) => handleOnKeyPressBarCode(e)}
              />
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 2,
              gap: 18,
              width: "100%",
            }}
          >
            <Buttons
              variant="primary"
              type="submit"
              disabled={loadData.item != "" ? false : true}
              onClick={axiosCall}
            >
              Load
            </Buttons>
            <Buttons
              variant="danger"
              type="submit"
              onClick={handleClearLoad}
              sx={{ mr: 1 }}
            >
              Clear
            </Buttons>
          </Box>

          <br />

          {Loading && (
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          )}

          {intialTime && !Loading && loadedData && <Results />}

          {intialTime && !Loading && !loadedData && (
            <Box>
              <Typography
                sx={{
                  color: "white",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                No Data Found
              </Typography>
            </Box>
          )}
        </div>
      </Box>
    </>
  );
}

export default ProdcutFinder;
