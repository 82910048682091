import { Box, Typography, Button,Grid,Paper, Divider } from "@mui/material";
import React,{useState} from "react";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import QrCodeIcon from '@mui/icons-material/QrCode';
import {useNavigate} from 'react-router-dom'
import BoxIcon from '../../Assets/box.png'
import TablesView from './helper/Tables'
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import logo  from '../../Assets/graph.png';
import logo2  from '../../Assets/grop.png';



import QRCODE from './helper/qrcodepage'
//Colors

import { textWhite, TextBlue,darkBlue,green,red } from "../../styled-Component/colors";


//STYLES
import { hCenter, vCenter, colorWhite,WhiteBorder,bgDarkBlue,pointer } from "../../styled-Component/elements";





//Icons
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

//images 

import PopulModel from './helper/Tables'



function TablePage() {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };








const navigate = useNavigate()


const [open,setOpen] = useState(false)

const toggleDrawer = () => {
  
  setOpen(!open);
};
const mystyle={
  textAlign: "center",
display: "block",
justifyContent: "center",
alignItems: "hcenter",
margin: "auto",


}



  return (
    
    
<>

{/*Model*/}

{/*Model End*/}

  <Box sx={{px:6}}>

  <Box >

  <Grid container sx={{ vCenter,pt:2}}>
      <Grid xs={1.5} sx={{...vCenter}}>
      <ArrowBackIosNewIcon onClick={()=>{navigate('/InvoiceList')}} sx={{fontSize:"11px",padding:.5,border:`1px solid ${TextBlue}`,color:TextBlue,borderRadius:"6px"}}/>
      </Grid>
      <Grid xs={10.5} >
      <Typography sx={{color:"white",fontSize:"14px",fontWeight:500}}>Tap on the desired item's barcode icon once you've picked up the product.</Typography>
      </Grid>
      </Grid>
  </Box>


    {/*InVoice Lists*/}

<br/><br/><br/><br/>
<div className="row">
  <div className="col-4">

  </div>
  <div className="col-4">
  <img srcSet={`${logo} 320w, ${logo} 680w, ${logo}   960w, ${logo} 1980w`} alt="Logo" className="center" style={mystyle}/>;
</div>
<div className="col-4">

</div>
</div>
   
<div className="row">
  <div className="col-4">

  </div>
  <div className="col-4">
  <img srcSet={`${logo2} 320w, ${logo2} 680w, ${logo2}   960w, ${logo2} 1980w`} alt="Logo" className="center" style={mystyle}/>;
</div>
<div className="col-4">

</div>
</div>


{/*New List*/}
{/* <Box sx={{mt:5}}>
{[0,1,2,3,4].map((item,index)=>{
return <Box sx={{mb:1.5,p:0 ,'& .css-ivyevq-MuiButtonBase-root-MuiAccordionSummary-root':{alignItems:"end"},'&.css-eqpfi5-MuiAccordionSummary-content.Mui-expanded':{
  m:0
},"& .css-eqpfi5-MuiAccordionSummary-content":{
  margin:0
}}}>
<Accordion sx={{backgroundColor:"transparent",m:0,p:0}}>
      <AccordionSummary
sx={{height:"auto",width:"100%",p:0,m:0}}
        aria-controls="panel1-content"
        id="panel1-header"
        
      >

        
      <Box disabled= {index==2 ? true: false} key={index} sx={{p:1,width:"100%",opacity: (index==2||index==3) ?  0.5 : 1, cursor:(index==2||index==3)  ?  'not-allowed' : "allowed",backgroundColor:"black", ...WhiteBorder,borderColor:(index==2||index==3)  ? "red": 'white' ,borderWidth:1,mt:0,}}>
      
      <Grid container sx={{  ...vCenter,p:0,m:0 }}>
    
    <Grid xs={2} sx={{...vCenter}}>
    <Box component={"img"} src={BoxIcon} sx={{width:"20px",...vCenter}}></Box>
    </Grid>
    
    <Grid xs={8} >
    <Box sx={{...hCenter}}>
    <Typography sx={{color:"grey",fontSize:"12px",fontWeight:500}}>Item Code </Typography> 
    <Typography sx={{color:"grey",fontSize:"12px",fontWeight:500}}>Selves </Typography>
    </Box>
    
    </Grid>


    <Grid xs={2} sx={{...vCenter,justifyContent:"end"}} >
    <QrCodeIcon onClick={()=>navigate('/QrCode')} sx={{...colorWhite,...pointer}} /> 
    </Grid>
    
</Grid>
      </Box>
      </AccordionSummary>
      <AccordionDetails sx={{width:"100%",p:0}}>
      <Box sx={{borderTop:"1px solid white",p:0}}>
      <TablesView/>
      </Box>
      </AccordionDetails>
    </Accordion></Box>
})}

</Box> */}


{/*Button*/}

{/* <Box sx={{mt:5,mb:3  }}> */}



{/* <Grid container spacing={2}> */}
  {/*<Grid item xs={5}>
  <Button variant="outlined" sx={{width:"100%",p:1,fontSize:"12px",...WhiteBorder,color:"white"}}>Get All</Button>
</Grid>*/}
  {/* <Grid item xs={12} sx={{textAlign:"center"}}>
  <Button onClick={()=>{navigate('/InvoiceList')}} variant="outlined" sx={{width:"50%",mx:"auto",p:1,fontSize:"12px",...WhiteBorder,color:"white",backgroundColor:"black"}}>Finished</Button>
  </Grid>
</Grid> */}



</Box>
{/*Button End*/}


  {/* </Box> */}


  
  </>
  );
}

export default TablePage;