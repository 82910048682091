import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableHeadIssue } from "../../../styled-Component/elements";
import { LinearLoader } from "../../../helper/linearLaoder";

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}

const rows = [createData("apple", 159, 6.0, 24, 4.0)];

const TableBodyStyle = {
    backgroundColor: "black",
    padding: "10px",
    fontSize: "10px",
    color: "white",
};

const header = ["Batch No", "Expiry", "Selves"];

export default function BasicTable({ data }) {
    // console.log(data);
    return (
        <TableContainer component={Paper}>
            <Table sx={{}} aria-label="simple table">
                <TableHead sx={{ ...TableHeadIssue, border: "0px !important" }}>
                    <TableRow
                        sx={{
                            backgroundColor: "transparent",
                            border: "0px !important",
                        }}
                    >
                        {header.map((item, index) => {
                            return (
                                <TableCell
                                    key={index}
                                    sx={{
                                        border: 0,
                                        backgroundColor: "black",
                                        padding: "10px",
                                        fontSize: "10px",
                                        color: "white",
                                    }}
                                    align="center"
                                >
                                    {item}
                                </TableCell>
                            );
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow
                        sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                        }}
                    >
                        <TableCell sx={{ ...TableBodyStyle }} align="center">
                            {data?.item_batch_no ? data?.item_batch_no : "-"}
                        </TableCell>
                        <TableCell sx={{ ...TableBodyStyle }} align="center">
                            {data?.item_expiry ? data?.item_expiry : "-"}
                        </TableCell>
                        <TableCell sx={{ ...TableBodyStyle }} align="center">
                            {data?.item_shelves ? data?.item_shelves : "-"}
                        </TableCell>
                        {/*<TableCell sx={{...TableBodyStyle}} align="center">0</TableCell>*/}
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
}
