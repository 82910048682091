import { Box, Typography,Button } from '@mui/material'
import IMage1 from '../../Assets/rafiki.png'
import {TextBlue,textWhite} from '../../styled-Component/colors'
import {useNavigate} from 'react-router-dom'
import React from 'react'
function LandingPage() {

  const navigate = useNavigate()
  return (
    <Box  sx={{color:textWhite,maxHeight:"50vh",borderRadius:1.2,textAlign:"center",border:"1px solid white",my:1,py:1,boxSizing:"border-box"}}>

    <Box component={'img'} src={IMage1} sx={{width:"70%",height:"20%",mx:"auto"}}/>
<Box sx={{textAlign:"center",mt:.5}}>
<Typography sx={{fontWeight:"bold" ,fontSize:"1rem",color:TextBlue}}>Dedicated <Typography component={"span"} sx={{fontWeight:"bold" ,color:textWhite,fontSize:"1rem"}}>Warehouse</Typography></Typography>
<Typography sx={{fontWeight:"bold",fontSize:"1rem" }}>Collector <Typography component={"span"} sx={{fontWeight:"bold" ,color:TextBlue,fontSize:"1rem"}}>Application</Typography></Typography>
</Box>


<Box sx={{textAlign:"center",mt:1,mb:1}}>
<Button variant="outlined" size="small" sx={{color:textWhite,fontSize:"14px",px:"1rem",border:"1.5px solid white",textTransform:"capitalize"}} onClick={()=>navigate('/dashboard')}>Go</Button>
</Box>

    </Box>
  )
}

export default LandingPage
