import { styled } from "@mui/material";
import { Box, Typography, Button } from "@mui/material";
import { textWhite, TextBlue, darkBlue } from "./colors";
import React from "react";
export const hCenter = {
    display: "flex",
    justifyContent: "space-between",
};

export const vCenter = {
    display: "flex",
    alignItems: "center",
};

export const pointer = {
    cursor: "pointer",
};

export const colorWhite = {
    color: textWhite,
};

export const bgDarkBlue = {
    backgroundColor: darkBlue,
};

export const TableHeadIssue = { display: "table-header-group !important" };

export const Btn = styled(Button)(
    ({
        border,
        bgcolors,
        opacity,
        borderRadius,
        Color,
        bgcolor,
        hoverBgColor,
        hoverColor,
        borderColor,
    }) => ({
        backgroundColor: bgcolor || bgcolors ? bgcolor || bgcolors : "",
        color: Color,
        borderRadius: borderRadius ? borderRadius : 0,
        borderColor: borderColor,
        border: border ? border : "",

        "&:active": {
            backgroundColor: bgcolor,
            color: Color,
            opacity: opacity ? opacity : 0.6,
            borderColor: borderColor && borderColor,
        },
        "&:focus": {
            backgroundColor: `${bgcolor} `,
            color: `${Color} `,
            opacity: `${opacity ? opacity : 0.6} `,
            borderColor: `${borderColor && borderColor} `,
        },

        "&:focus:not(:hover)": {
            backgroundColor: bgcolor,
            color: Color,
            opacity: opacity ? opacity : 0.6,
            borderColor: borderColor && borderColor,
        },

        "&:hover": {
            backgroundColor: hoverBgColor ? hoverBgColor : bgcolor,
            color: hoverColor ? hoverColor : Color,
            opacity: opacity ? opacity : 0.6,
            borderColor: borderColor && borderColor,
        },
    })
);

export const WhiteBorder = {
    border: "1px solid white",
};


export const NavButton = {

}

export const containerStyle = {
    width: "93%", 
    mx: "auto",
    mt: 2
}


