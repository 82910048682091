import { Url } from "../Axios/Axios";
import { token } from "./token";
export const apicall = async (url, method, body) => {
  if (!token) {
    throw new Error("User token is not available");
  } else {
    const response = await fetch(`${Url}${url}`, {
      method: method.toUpperCase(),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token()}`,
      },
      body: body,
    });

    return response;
  }
};
