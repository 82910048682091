import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

import { BgBlackImage } from "./styled-Component/colors";
import { HashRouter } from "react-router-dom";
import { Box } from "@mui/material";

import { store } from "./store/store";
import { Provider } from "react-redux";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { Toaster } from "react-hot-toast";
const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));



root.render(
    <QueryClientProvider client={queryClient}>
        <Box
            sx={{
                backgroundColor: BgBlackImage,
                minHeight: "100vh",
                width: "100%",
                margin: 0,
                padding: 0,
                overflow: "scroll",
            }}
        > 
            <Provider store={store}>
                <HashRouter>
                    <Toaster />
                    <App />
                </HashRouter>
            </Provider>
        </Box>
    </QueryClientProvider>
);


