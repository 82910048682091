import {
  ArrowBackIosNew,
  ExpandLess,
  ExpandMore,
  Menu,
} from "@mui/icons-material";
import {
  Box,
  Collapse,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
} from "@mui/material";
import React from "react";
import { TextBlue } from "../styled-Component/colors";
import { useNavigate } from "react-router-dom";
import { path_access } from "./token";
import { MainMenu, paths } from "./constant";
import { ApiCall } from "./Apicall";

function HeaderDrawer() {
  const navigate = useNavigate();
  const stylesForIcon = {
    fontSize: "35px",
    color: "white",
    border: "1px solid white",
    borderRadius: 50,
    padding: ".5rem",
  };

  const pathsAcess = path_access();
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [activePath, setActivePath] = React.useState({
    MainMenu: [],
    SubMenu: [],
  });

  const toggleDrawer = (newOpen) => () => {
    setDrawerOpen(newOpen);
  };

  const datas_from_db = async () => {
    let { data, error } = await ApiCall("menus", "get");
    if (data) {
      let MainMenu = data.filter((itm) => itm.menuid < 1000);
      let SubMenu = data.filter((itm) => itm.menuid > 1000);

      setActivePath({ MainMenu, SubMenu });
    } else {
      console.log(1002);
    }
  };

  React.useEffect(() => {
    datas_from_db();
  }, []);

  const [listOpen, setListOpen] = React.useState(null);

  const handleMenuToggle = (id) => {
    setListOpen((prevOpenMenu) => (prevOpenMenu === id ? null : id));
  };
  const DrawerList = (
    <Box sx={{ width: 250, height: "100%" }} role="presentation">
      <Stack spacing={0} sx={{ mt: "20%" }}>
        {MainMenu?.length > 0 &&
          MainMenu.map((item, index) => (
            <List
              key={index}
              sx={{
                width: "100%",
                backgroundColor: "black",
                borderBottom: "1px solid white",
                color: "white",
              }}
              component="nav"
              aria-labelledby="nested-list-subheader"
            >
              <ListItemButton onClick={() => handleMenuToggle(item.id)}>
                <ListItemIcon>
                  <item.Logo sx={stylesForIcon} />
                </ListItemIcon>
                <ListItemText primary={item.Content} />
                {listOpen === item.id ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={listOpen === item.id} timeout="auto" unmountOnExit>
                {paths
                  .filter((item1) => item1.refId == item.id)
                  .map((item2, index2) => {
                    let { Logo } = item2;

                    return pathsAcess === "all" ? (
                      activePath?.SubMenu?.filter(
                        (item3) =>
                          item3.menuid == Number(item2.id) &&
                          item3.isactive === 1
                      ) && (
                        <ListItemButton
                          sx={{ pl: 4 }}
                          key={index2}
                          onClick={() => {
                            if (item2.Content == "Placer") {
                              localStorage.setItem("type", "Placer");
                            } else if (item2.Content == "Collector") {
                              localStorage.setItem("type", "Collector");
                            } else if (item2.Content == "BTV Picking") {
                              localStorage.setItem("type", "BTV Picking");
                            } else if (item2.Content == "Purchase Receiving") {
                              localStorage.setItem(
                                "type",
                                "Purchase Receiving"
                              );
                            }
                            navigate(item2.to);
                            toggleDrawer(false);
                          }}
                        >
                          <ListItemIcon>
                            <Logo sx={{ color: "white" }} />
                          </ListItemIcon>
                          <ListItemText primary={item2.Content} />
                        </ListItemButton>
                      )
                    ) : (
                      <ListItemButton
                        sx={{ pl: 4 }}
                        key={index2}
                        onClick={() => {
                          if (item2.Content == "Placer") {
                            localStorage.setItem("type", "Placer");
                          } else if (item2.Content == "Collector") {
                            localStorage.setItem("type", "Collector");
                          } else if (item2.Content == "BTV Picking") {
                            localStorage.setItem("type", "BTV Picking");
                          } else if (item2.Content == "Purchase Receiving") {
                            localStorage.setItem("type", "Purchase Receiving");
                          }

                          navigate(item2.to);
                          toggleDrawer(false);
                        }}
                      >
                        <ListItemIcon>
                          <Logo sx={{ color: "white" }} />
                        </ListItemIcon>
                        <ListItemText primary={item2.Content} />
                      </ListItemButton>
                    );
                  })}
              </Collapse>
            </List>
          ))}
      </Stack>
    </Box>
  );
  return (
    <Stack direction="row" gap={2} alignItems="center">
      <Menu onClick={toggleDrawer(true)} sx={{ color: "white" }} />
      <Drawer
        open={drawerOpen}
        sx={{
          height: "100%",

          "& .MuiDrawer-paper": {
            height: "100vh",
            borderRadius: "0",
            borderColor: "transparent",
            background: "rgb(0, 13, 29)",
          },
        }}
        onClose={toggleDrawer(false)}
      >
        {DrawerList}
      </Drawer>
      <ArrowBackIosNew
        onClick={() => {
          navigate("/mainPage");
        }}
        sx={{
          fontSize: "20px",
          padding: 0.5,
          border: `1px solid ${TextBlue}`,
          color: TextBlue,
          borderRadius: "6px",
        }}
      />
    </Stack>
  );
}

export default HeaderDrawer;
