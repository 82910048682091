import toast from "react-hot-toast";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import React from "react";

export const Toast = (type, content, time = 2000) => {
    toast[type](content, {
        duration: time,
        position: "top-center",

        // Styling
        className: "",

        // Custom Icon
        icon:
            type === "error" ? (
                <CloseIcon fontSize="small" />
            ) : type === "success" ? (
                <CheckCircleOutlineOutlinedIcon />
            ) : null,

        // Change colors of success/error/loading icon
        iconTheme:
            type === "error" || type === "success"
                ? {
                      primary: "#000",
                      secondary: "#fff",
                  }
                : {},

        style: {
            backgroundColor: type === "error" ? "red" : "green",
            color: "white",
            fontWeight: "bold",
            fontFamily: "inherit",
            textAlign: "center",
        },

        // Aria
        ariaProps: {
            role: "status",
            "aria-live": "polite",
        },
    });
};
